.content-title-bar {
  justify-content: space-between !important;
}

.new-case {
  float: right;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4e803b !important;
  height: 44px;
}
.block {
  height: 36px;
  width: 100%;
  background-color: white;
  z-index: 1000;
}