.cancel-icon {
  position: relative;
  top: 7px;
  bottom: 7px;
  font-size: 30px !important;
  margin-right: 9px;
  background-color: #ffffff;
  color: #da291c;
}

.check-icon {
  color: #ffffff;
  background-color: #4e803b;
  position: relative;
  font-size: 30px !important;
  margin-right: 9px;
  top: 10px;
  border-radius: 50%;
}

.cancel-margin {
  margin-right: 9px;
}

.center {
  margin: auto;
  width: 50%;
  text-align: center;
  padding-top: 85px;
}

.browser-warning {
  height: 32px;
  font-family: OpenSans-Semibold, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #111212;
  margin: 0px 0 32px 0;
}

.browser-text {
  font-family: OpenSans-Semibold, sans-serif;
}

.splitter-width {
  margin: 45.5px 0 45.5px 0;
  border-top: 1px dashed #979797;
  border-bottom: none;
}

.to-use-manulife {
  width: 750px;
  height: 20px;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #111212;
  margin: 0px 0px 45px 0px;
}

.illustrations-input {
  border-radius: 3px;
  border: solid 1px var(--duck-egg-blue);
  background-color: var(--duck-egg-blue);
  margin: 0px 0px 45px 0px;
}

.input-for-ie {
  width: 395px;
  height: 44px;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #111212;
  background-color: #e0e3e2;
}

.copy-button {
  width: 100px !important;
  height: 44px !important;
}

.link-copied {
  font-family: OpenSans-SemiBold, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.column {
  padding: 10px;
  display: inline;
}

.right {
  width: 10%;
}

.left {
  width: 60%;
}

.middle {
  width: 10%;
}

/* Clear floats after the columns */
/* .row:after {
  content: '';
  display: table;
  clear: both;
} */

.download-chrome-edge {
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #111212;
  margin: 45px 0 12px 0;
}

.learn-more {
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #111212;
  /* margin: 0px 454px 12px 455px; */
}

.end-of-page-margin {
  margin-bottom: 85px;
}

.download-chrome-edge a:link a:visited a:hover a:active {
  color: #004427;
  text-decoration: underline;
}

.learn-more a:link a:visited a:hover a:active {
  color: #004427;
  text-decoration: underline;
}
