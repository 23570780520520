.mwi-dropdown-container .italic {
  font-style: italic;
}

.mwi-dropdown-container .material-icons {
  color: #111212 !important;
}

.Select-option.is-selected {
  background-color: #4e803b !important;
  border-color: #4e803b !important;
  color: #ffffff;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #d6d6d6 !important;
}

.Select-option.is-focused:not(.is-selected) {
  background-color: #eeeeee !important;
}

.top-bar-field {
  height: 28px !important;
}

.top-bar-field + .material-icons {
  top: 6px !important;
}

.select-dropdown-search {
  font-size: 14px;
  font-family: 'OpenSans-Regular', sans-serif;
  /* padding-left: 13px; */

  /* Remove standard styling from all browsers: */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: none;
  -webkit-writing-mode: horizontal-tb !important;
  writing-mode: horizontal-tb !important;

  /* Actual styling: */
  height: 36px;
  width: 100%;
  border: 1px solid #e0e3e2;
  color: #111212;
  text-indent: 3px;
  border-radius: 3px;
  box-shadow: none;
  background-color: #ffffff;
  background-image: none;
  outline: none;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  padding-left: 13px;
}
.Select-clear {
  display: none
}

/* Remove dropdown arrow on IE */
select::-ms-expand {
  display: none;
}

/* Remove inner blue focus outline on IE */
select:focus::-ms-value {
  color: #333;
  background-color: #ffffff;
}

.select-dropdown.disabled {
  background-color: #ededed !important;
}

.select-dropdown > .material-icons {
  vertical-align: bottom !important;
}

.vitalitystatus-helptext-size-en{
  width:500px !important;
  max-width: 500px !important;
}

.vitalitystatus-helptext-size-fr{
  width:615px !important;
  max-width: 615px !important;
}

.select-dropdown-search .css-1wa3eu0-placeholder,
.select-dropdown-search .css-1uccc91-singleValue {
  margin-left: 5px;
}

.select-dropdown-search .css-1okebmr-indicatorSeparator {
  margin-right: 8px;
}