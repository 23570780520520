.button {
  position: absolute;
  right: -3px !important;
  top: -2px !important;
}
.exit {
  top: -50px;
}
.ReactModal__Content.fullscreen-fullmode {
  position: relative;
  width: 98% !important;
  height: 85% !important;
  margin: 0 auto !important;
  padding: 0 !important;
}
.fullscreen-button {
  position: absolute !important;
  right: 10px !important;
  margin-top: 15px;
  color: darkgreen !important;
  font-size: small;
  cursor: pointer;
  width: 90px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.line {
  width: 8px;
  height: 30px;
  border-left: solid #acc0d8 1.5px;
}

@media (max-width: 1024px) {
  /* .form-wrapper {
    padding: 20px 10px !important;
  } */
  .button {
    width: 39.45% !important;
  }
}
