.p-tabview {
  padding: 0.25em;
}

.p-tabview .p-tabview-nav {
  margin: 0;
  border: 1px solid #ededed !important;
}

.p-tabview-panels {
  border: 1px solid #ebebeb !important;
  padding-left: 18px !important;
}

.p-tabview .p-tabview-nav li a {
  float: left;
  padding: 0.5em 1em;
  text-decoration: none;
  cursor: pointer;
}

.p-tabview .p-tabview-nav li a:focus {
  outline: none !important;
}

.p-tabview .p-tabview-nav:after {
  content: '';
  display: table;
  clear: both;
}

.p-tabview .p-tabview-nav li {
  list-style: none;
  float: left;
  position: relative;
  margin: 0 5px 1px 0;
  padding: 0;
  white-space: nowrap;
  display: block;
}

.p-tabview .p-tabview-nav li.p-tabview-selected a,
.p-tabview .p-tabview-nav li.p-disabled a,
.p-tabview .p-tabview-nav li.p-state-processing a {
  cursor: text;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a {
  margin-right: 0px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
  border-radius: 0px;
  color: #004427;
  font-weight: normal;
  background-color: inherit;
}

.p-tabview.p-tabview-collapsible .p-tabview-nav li.p-tabview-selected a {
  cursor: pointer;
}

.p-tabview .p-tabview-panel {
  border-width: 0;
  padding: 1em;
  background: none;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon,
.p-tabview .p-tabview-nav li .p-tabview-right-icon,
.p-tabview .p-tabview-nav li .p-tabview-title {
  vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-right-icon {
  margin-left: 0.25em;
  vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-close {
  margin: 0.5em 0.3em 0 0;
  cursor: pointer;
}

/* per orientation settings */
/* top and bottom */
.p-tabview.p-tabview-top > .p-tabview-nav li {
  border-bottom: 0;
  top: 1px;
}

.p-tabview-left > .p-tabview-nav {
  float: left;
  width: 25%;
  height: 300px;
  background-image: none;
  padding-top: 1px;
}

.p-tabview.p-tabview-top > .p-tabview-nav {
  padding: 0.2em 0.2em 0;
  margin-top: 1px;
}

.p-tabview.p-tabview-bottom > .p-tabview-nav {
  padding: 0 0.2em 0.2em;
}

.p-tabview.p-tabview-bottom > .p-tabview-nav li {
  border-top: 0;
}

/* left and right*/
.p-tabview-left:after,
.p-tabview-right:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
}

.p-tabview-left > .p-tabview-panels {
  float: right;
  width: 75%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li,
.p-tabview.p-tabview-right > .p-tabview-nav li {
  display: block;
  float: right;
  white-space: normal;
  width: 99%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li {
  margin: 0 0 1px 0;
  border-right: 0 none;
}

.p-tabview.p-tabview-right > .p-tabview-nav {
  float: right;
  width: 25%;
  height: 300px;
  background-image: none;
  padding-top: 1px;
}

.p-tabview.p-tabview-right > .p-tabview-panels {
  float: left;
  width: 75%;
}

.p-tabview.p-tabview-right > .p-tabview-nav li {
  margin: 0 0 1px 0;
  border-left: 0 none;
}
/* RTL */
.p-rtl .p-tabview .p-tabview-nav li {
  float: right;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-selected a {
  background-color: inherit;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 4px;
  font-weight: 700;
  border-bottom-color: #004427;
  color: #004427;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
  background-color: inherit;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 4px;
  border-bottom-color: #004427;
  color: #004427;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
  background-color: inherit;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 4px;
  border-bottom-color: #004427;
  color: #004427;
}

.p-highlight {
  background-color: inherit !important;
}
