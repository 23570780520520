.label-link {
  color: #2f622f;
  text-decoration: underline;
  cursor: pointer;
}

.temp-margin-top8 {
  margin-top: 8px;
  border-style: solid;
}

.trc-help {
  cursor: pointer;
}

.trc-help > .material-icons {
  margin-left: 13px;
  /* margin-top: 2px; */
  font-size: 17px !important;
}

.trc-help-div {
  display: flex;
}

.trc-sub-section {
  display: flex;
}

.trc-help-text {
  display: flex;
}

#coverageEffectiveDateTip {
  width: auto;
  max-width: 750px;
}

.trc-help-text i.material-icons {
  font-size: 10px;
}

.calculator-section-title {
  margin-top: 18px;
  margin-bottom: 8px;
  font-family: OpenSans-Bold, serif;
  color: #4a4a4a;
}

.space-left {
  padding-left: 25px !important;
}

.healthstyle-trc {
  height: fit-content;
  position: relative;
  right: 15px;
}

.healthstyle-trc > .material-icons {
  font-size: 17px !important;
}

.dob-calendar .mwi-error-field {
  border-color: #d70909 !important;
}

.dob-calendar .mwi-error-msg {
  font-size: 13px !important;
  color: #d70909 !important;
}
