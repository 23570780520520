.tab-nav {
  position: relative;
  height: 46px;
  background-color: #ffffff;
}

.tab-nav .label {
  font-size: 16px;
  position: relative;
  padding-left: 5px;
}

.tab-nav .tab-header {
  position: relative;
  height: 36px;
  bottom: -5px;
  padding: 4px 0px 4px 8px !important;
  text-align: center;
  color: #111212;
  font-size: 18px !important;
  /* font-weight: bold; */
  border: 1px solid #c0c0c0;
  border-left: none;
  border-bottom: none;
  cursor: pointer;
  background-color: white;
}

.tab-nav .remove {
  position: absolute;
  left: 24px;
  top: 5px;
  /* border: 2px solid #da291c;
  border-radius: 50%; */
  width: 18px;
  height: 18px;
}

.tab-nav .remove i {
  position: relative;
  /* top: 12px;
  left: 24px; */
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
}

.tab-nav .tab-header:first-child {
  border-left: 1px solid #c0c0c0;
}

.tab-nav .tab-header.active {
  bottom: 0;
  height: 36px;
  font-weight: bold;
  border-top-width: 6px;
  border-top-color: #00693c;
}

.tab-nav .tab-header.add {
  width: 46px;
  border-left: none;
}

.tab-nav .tab-header.add .label {
  top: -4px;
  left: 3px;
}

.tab-nav .tab-header.add i {
  position: relative;
  font-size: 1.5rem !important;
  color: #00693c;
  top: 3px;
  left: -4px;
}

@media (max-width: 1024px) {
  .tab-nav .remove {
    left: 17px;
  }

  /* .tab-nav .label {
    font-size: 15px;
    left: 9px;
  }
  .tab-nav .remove {
    left: 5px;
  }
  .tab-nav .remove i {
    top: -2px;
    left: 1px;
  }
  .tab-nav .tab-header {
    padding: 5px !important;
  }
  .tab-nav .tab-header.add i {
    left: 0;
  } */
}