.pageoption-checkbox-wrapper {
  margin-bottom: 15px;
}
.pageoption-checkbox-wrapper .material-icons {
  position: absolute;
  top: 2px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  pointer-events: none;
}

.pageoption-checkbox-wrapper .ui-state-disabled {
  opacity: 1 !important;
}
.pageoption-checkbox-wrapper label.disabled {
  color: #767676;
}

.pageoption-checkbox {
  margin-right: 5px;
  vertical-align: middle;
}

/* .pageoption-checkbox-wrapper .tm {
  position: relative;
  top: -4px;
  font-size: 10px;
} */
