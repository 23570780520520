@font-face {
  font-family: 'OpenSans-light';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/opensans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/opensans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/opensans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/opensans/OpenSans-Bold.ttf') format('truetype');
}
