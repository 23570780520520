/* Dropdown */
.top-bar-font-size {
  font-size: 12px !important;
}
.sce-subtab-navs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.sce-subtab-navs a {
  text-decoration: none;
  flex: 1;
}
.disabled-link {
  pointer-events: none;
}
.sce-subtab-navs .tab-header {
  border-left: 1px solid white;
  padding: 28px 0px !important;
  font-size: 14px;
  text-align: center;
  color: white;
  font-weight: bold;
  align-self: center;
  /* border-left: solid 1px #ffffff; */
}
.sce-subtab-navs .tab-header:not(.disabled) {
  cursor: pointer;
}
.sce-subtab-navs .tab-header.active {
  background-color: #0f3329 !important;
  color: white;
}
.sce-subtab-navs button {
  color: white;
  font-family: inherit;
  font-size: 12px;
  background-color: transparent;
}
/* .sce-subtab-navs button:focus {
  outline: none;
} */
.sce-subtab-navs button:disabled {
  color: gray;
  cursor: default;
}

.subtab-navs-wrapper {
  background-color: #00693c;
  height: 55px;
}
.subtab-navs-wrapper .mwi-label {
  color: white;
}
.product-wrapper {
  margin-top: 2px;
}
.subtab-navs-wrapper .product-wrapper {
  padding: 9px 24px !important;
}
.product-wrapper .select-dropdown {
  width: 100% !important;
}
/*
.effective-date {
  margin: 0px 0 0 20px;
}
*/
/* @media (max-width: 1100px) {
  .sce-subtab-navs .tab-header {
    padding: 30px 20px !important;
  }
} */
/* this style only applies when user is on IE */
.no-top-bottom-padding .mwi-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
@media (max-width: 999px) {
  .product-wrapper .p-col-6 {
    width: 92% !important;
  }
}
