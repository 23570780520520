/* .form-main-concepts {
  padding: 10px;
  padding-left: 24px;
  padding-top: 24px;
  border: 1px solid #c0c0c0;
} */
.form-main-concepts .accordionDiv:last-child {
  margin-bottom: -25px;
}

.concepts-panel > .form-main-concepts > div:nth-child(2) {
  margin-top: 24px !important;
}
/* .form-main .mwi-control {
  min-height: 65px;
} */
.form-main .p-col-12.no-margin {
  position: absolute;
  top: 5px;
  margin-bottom: 0;
}
.form-main .ui-inputgroup input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.form-main .ui-inputgroup-addon {
  padding-top: 0.36em;
}

.form-main .coverage-client {
  position: relative;
}

.alt-invst-sec-label {
  float: right;
  text-align: right;
  margin-right: 23px;
  width: max-content;
  margin-top: 5px !important;
}
.alt-invest-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.alt-invest-wrapper {
  margin-top: 0 !important;
}
.alt-invst-sec-label.first-child {
  margin-top: 30px !important;
}
.alt-invest-error-label {
  color: #ea0303;
  font-size: 14px;
}
.allocation-input-error {
  border: 1px solid #ea0303 !important;
  color: #ea0303 !important;
}
.concept-component {
  margin-top: 24px;
}
.select-concept-info {
  margin-top: 30px;
}
#concept-icon-container {
  padding-right: 24px;
  padding-left: 24px;
}
.ceb-list {
  padding-left: 15px;
}
.help-icon .p-overlaypanel {
  width: 200px !important;
}
.help-icon-wide .p-overlaypanel {
  width: 520px !important;
}

#dividend-scale-accordion {
  margin-bottom: 25px;
}

.email-link {
  font-family: OpenSans-Regular, serif;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #00693c;
  text-decoration-color: #00693c;
  cursor: pointer;
  color: #004427;
}
