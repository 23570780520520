.app-footer {
  padding-left: 80px;
  margin-top: auto;
}

.app-footer-small {
  padding-left: 80px;
  width: 72.5%;
  margin-top: auto;
}

#app-footer-bar {
  z-index: 0;
}

#app-footer-bar-a-feedback {
  cursor: pointer;  
}

@media (max-width: 1367px) {
  .app-footer-small {
    padding-left: 80px;
    width: 70.5%;
  }
}
