.dob-calendar .p-calendar .p-calendar-button {
  color: black;
  background-color: transparent;
  border: 1px solid #a6a6a6;
  border-left: none;
}

.dob-calendar .p-button:enabled:hover {
  background-color: transparent;
  color: black;
  border-color: #a6a6a6;
}

.dob-calendar .p-datepicker table td>span.p-highlight {
  color: #ffffff;
  background-color: #046138 !important;
}

.dob-calendar .mwi-label {
  margin-bottom: 8px;
}

.trc-space-right {
  padding-right: 15px !important;
}

#coverage-amount-label {
  padding-bottom: 5px;
}