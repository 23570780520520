#shareIllustration-wrapper-id {
    padding: 0;
    height: auto;
    width: 650px;
}

#shareIllustration-wrapper-id .title-model {
    height: 47px;
    padding: 13px 13px 13px 20px !important;
    font-size: 14px;
    font-weight: normal;
    background-color: #f7faf9;
    color: #00693c;
}

#shareIllustration-wrapper-id .row-model .error {
    margin-top: 16px;
    font-size: 13px;
}

.cancelShareLinkButton {
    font-size: 14px;
    background-color: #f7faf9 !important;
    color: black !important;
    border-color: #f7faf9 !important;
    font-weight: bold;
    height: 47px !important;
}

#shareIllustration-wrapper-id div div .row-model {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-end;
    font-size: 14px;
}

#shareIllustration-wrapper-id div div .shareIllustrationContent {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-end;
    font-size: 14px;
    padding: 15px 20px 5px;
}

#txt-shareLinkInput {
    border-radius: 3px;
    background-color: #f5f5f5;
    width: 452px;
    z-index: 1;
    border: solid 1px #e0e3e2;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: black !important;
}

#shareIllustrationCopyButton {
    width: 116px;
}

#txt-shareLinkInput-noSelect {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    padding-top: 10px;
    padding-right: 0;
    width: 465px;
}