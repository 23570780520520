input:disabled {
  background-color: #eee;
}
.lumpsum-container.allocation-error {
  margin-top: 24px !important;
}
.lumpsum-container .p-chkbox-label {
  margin-top: -4px;
}
.lumpsum-amount-checkbox {
  margin-right: 0.5em;
}
.ui-input {
  margin-bottom: 24px;
}
.allocation-strategy {
  float: left;
}
.alternate-container {
  margin-bottom: 24px;
}
.allocation-buttons {
  min-width: 65%
}
/* to avoid conflicts with the !important flag on the mwi-w75 class we define our max-width here so it can be overridden by the media queries*/
.m-w75 {
  max-width: 75%;
}
@media screen and (max-width: 1024px) {
  .en-w85 {
    max-width: 85%;
  }
  .number-disabled {
    color: #262626 !important;
  }
}
