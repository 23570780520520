.dashboard-wrapper .new-case {
  float: right;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4e803b !important;
  height: 44px;
}

.label-tca{
  margin-bottom: 1.5rem;
  margin-right: 0.5rem;
}

.label-insured-tca{
  margin-bottom: 1.8rem;
}

.label-tca-fr{
  margin-bottom: 3rem;
}

.label-insured-tca-fr{
  margin-bottom: 3.4rem;
}