.title-model {
  border: 0px !important;
}

.caseSave-wrapper {
  padding: 0px 20px 0px 0px !important;
  height: auto !important;
  width: 458px !important;
}

.caseSave-wrapper .title-model {
  height: 47px;
  padding: 13px !important;
  /* font-size: 1.25em !important; */
  font-size: 14px;
  font-weight: bold;
  background-color: #f7faf9;
  color: #00693c;
  padding-left: 24px !important;
}
.caseSave-wrapper .row-model {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  margin-bottom: 0 !important;
  margin-top: 24px !important;
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
}

.rating-footer {
  margin-bottom: 20px;
}
.caseSave-wrapper .row-model .error {
  margin-top: 16px !important;
  font-size: 13px;
}

.caseSave-error {
  font-size: 13px;
  color: red;
  padding-top: 6px;
}

.mwi-dropdown-container {
  margin-bottom: 3px;
}

.savecase-input .select-dropdown {
  color: #111212 !important;
}

.mwi-label {
  margin-bottom: 8px !important;
}

.savecase {
  margin-top: 24px;
}

.saveas-cancel-button {
  display: block;
  top: 1px;
  background-color: #ffffff !important;
  color: #4e803b !important;
  font-size: 14px !important;
  border: 2px solid #4e803b !important;
}

#dd-saveCaseForDropdown {
  border: 1px solid #e0e3e2;
}

#txt-saveCase {
  border: 1px solid #e0e3e2;
}

.ddDefault select {
  color: #4a4a4a;
}

.ddSelected select {
  color: #111212 !important;
}

select option {
  color: #4a4a4a;
}

.material-icons {
  font-size: 17px !important;
}

#help-icon {
  padding-left: 8px;
}
