.ReactModal__Content.error-dialog-wrapper {
  width: 405px !important;
  height: auto !important;
  position: relative;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.error-dialog-wrapper .row-model {
  padding-right: 24px;
}

/* ? - custom window size for Max Withdrawal Warning */
.ReactModal__Content.warning-max-withdrawal-width {
  width: 430px !important;
}

/* Sizing for Withdrawals Table parameters */
#maxWithdrawalTable  {
  border-collapse: collapse;
  width: 380px ;
}

/* Cells padding & Borders colors parameters */
#maxWithdrawalTable th, #maxWithdrawalTable td {
  padding: 10px;
  text-align: left;
  border: solid 1px #ededed !important;
}

#maxWithdrawalTable tbody, #maxWithdrawalTable thead {
  display: block;
} 

/* Column header & regular data width */
/* Year column */
th.yearColHeader, td.yearColData {
  width: 68px !important;
}
/* Requested withdrawal column sizing */
td.reqWithdrawalCol, th.reqWithdrawalColHeader {
  width: 158px !important;
}
/* Max withdrawal column sizing */
td.maxWithdrawalCol {
  width: 136px !important;
}

/* French custom max width resize */
th.reqWithdrawalColHeaderFr, td.overflowTableWithdrawalCol {
    width: 156px !important;
}

/* Header column font styling color */
#maxWithdrawalTable thead tr  th {
  color: #00693c;
}

/* Set height of table with scrolling of content of the body */
#maxWithdrawalTable tbody {
  overflow-y: auto;  
  height: 274px;
}

/* Inverse button 'Cancel' */
.inverseBtn {
  background-color: #ffffff !important;
  color: #00693c !important;
}