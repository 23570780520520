.ReactModal__Content.view-report-modal {
  min-height: 170px !important;
  font-size: 13px;
}

.view-report-btn {
  display: contents;
}

.dialog-footer {
  padding: 24px !important;
  margin-top: 24px;
}

.dialog-footer button {
  margin-right: 24px;
}

.save-button {
  float: right;
}

.report-cancel-btn {
  float: right;
}

#dd-viewReportDropDown {
  background-color: #4e803b;
  color: white;
  height: 44px;
  text-indent: 35px;
}

#dd-viewReportDropDown option {
  background-color: white;
}

#dd-viewReportDropDown + i {
  color: white !important;
  top: 14px;
  right: 12px;
}
