.accordion {
  border: 1px solid #979797;
  margin-top: -1px;
  /* border-left: none;
  border-right: none; */
  background-color: #e9f0ed;
  height: 48px;
  padding-left: 12px;
}

.accordion .title {
  position: relative;
  font-size: 16px;
  top: 14px;
}

.accordion .title-group-when-expanded {
  position: relative;
  left: -6px;
  display: inline-flex;
  align-items: center;
  cursor: default;
  padding-left: 24px;
}

.accordion .title-group {
  position: relative;
  left: -6px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.accordion .short-form i.material-icons {
  float: left;
  font-size: 18px;
  padding-right: 6px;
}
.accordion .rightContent-remove {
  float: right;
  padding-right: 0px;
}
.accordion .rightContent {
  float: right;
  padding-right: 24px;
  cursor: pointer;
}
