.header {
  color: white;
  background-color: white;
  padding-bottom: 15px;
}

.header-error {
  min-height: 180px;
}

.header-link a {
  font-family: OpenSans-Regular, serif;
  font-size: 14px;
  text-align: center;
  color: #004427;
  text-decoration: underline;
  text-decoration-color: #00693c;
  cursor: pointer;
}

.header-link {
  height: 19px;
  margin: 0;
  width: auto;
  padding-left: 0px !important;
  padding-right: 24px !important;
  margin-top: 10px;
  text-align: right;
}

/* HOME ICON */
.home-icon {
  width: 50%;
  height: 24px;
  font-family: 'Material Icons', serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: auto;
}
.help-icon-container {
  min-width: 48px;
  height: 44px;
}

.home-icon-container {
  min-width: 53px;
  height: 44px;
}

.accessibility-icon-container {
  min-width: 48px;
  height: 44px;
}

.lang-container {
  min-width: 44px;
  height: 44px;
}

.accessibility-icon {
  width: 15px;
  height: 20px;
  object-fit: contain;
}
.green-background {
  background-color: #00693c;
}

.icon-nounderline {
  height: 25px;
  max-width: 20px;
}

.icon-underline {
  height: 25px;
  max-width: 20px;
}

a:hover {
  color: #06874e;
}

.right-header-link a {
  font-family: OpenSans-Regular, serif;
  font-size: 14px;
  color: #004427;
  text-decoration: underline;
  text-decoration-color: #00693c;
  cursor: pointer;
}

.logout-box a {
  background-color: #ffb500;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.logout-box {
  font-family: 'OpenSans-SemiBold', serif;
}

/* TOP-BAR */
.top-bar > ul li a {
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.top-bar .profile-name a {
  position: relative;
  top: 0px;
  text-transform: none !important;
}

.top-bar .profile-action a {
  height: 44px;
}

.top-bar .profile-action > a {
  padding: 11px 0px !important;
}

.profile-action .action-menu > a {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: normal;
  padding: 10px;
  color: #111212;
  text-align: center !important;
  text-transform: none !important;
  border: 1px solid #c0c0c0;
}

.logout-box a:hover {
  background-color: #ffb500 !important;
  opacity: 0.8;
}

.logout-box span {
  color: #111212;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 27px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
}

.top-bar {
  position: relative;
  height: 44px;
  background-color: #16231c;
}

.top-bar .home {
  background-color: #00693c;
}

.top-bar .home:hover .manupicto {
  border-color: white;
}

.top-bar .domain {
  background-color: #0f3329;
}

.top-bar > ul.navbar-left li a {
  padding: 11px 27px;
}

.top-bar > ul.navbar-right li a {
  padding: 11px 12px;
}

.top-bar .profile-select {
  margin-top: -24px !important;
  padding: 11px 14px;
  width: 190px;
}

.top-bar .profile-select .select-dropdown {
  background-color: #00693c;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.top-bar .profile-select i {
  position: absolute !important;
  top: 13px !important;
  left: 85% !important;
  color: white !important;
  opacity: unset;
}

.top-bar .profile-select .mwi-dropdown-container {
  padding-top: 4px !important;
}

.profile-label {
  display: none;
}

.top-bar .profile-name {
  border-right-style: solid;
  border-width: 2px;
  border-color: black;
  height: 44px;
}

.top-bar .profile-name .profile-link {
  position: relative;
  left: 3px;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  color: white;
  text-decoration: none;
  padding: 11px 20px;
}

.top-bar .profile-name i {
  position: relative;
  top: 6px;
  left: 14px;
}

.top-bar .profile-name-group {
  position: relative;
  height: 44px;
  display: inline-flex;
}

.top-bar .profile-name-group:hover,
.top-bar .profile-name-group:hover .select-dropdown {
  background-color: #4e801f;
}

.top-bar .profile-name-group .select-dropdown option {
  background-color: white;
  color: #4a4a4a;
}

.top-bar .profile-name-group .select-dropdown:focus::-ms-value {
  background-color: #4e801f;
  color: white;
}

.top-bar .profile-name-group .profile-name-icon {
  font-size: 32px !important;
}

.top-bar .profile-action {
  position: relative;
}

.top-bar .profile-action .more-wrapper {
  padding: 0px 10px;
}

.top-bar .profile-action > a:focus {
  background-color: #4e801f;
}

.top-bar .profile-action .profile-action-icon {
  font-size: 18px;
  padding: 2px 8px !important;
}

.action-menu {
  right: 0px;
}

.profile-action .action-menu {
  position: absolute;
  width: 160px;
  /* border: 1px solid #c0c0c0; */
  background-color: white;
  color: black;
  z-index: 10002;
  cursor: pointer;
}

.profile-action .action-menu > a:hover {
  background-color: #4e801f;
  color: white;
}

.profile-action .action-menu > a:first-child {
  border-bottom: 0px solid #c0c0c0;
}

.profile-action .action-menu > a:last-child {
  border-top: 0px solid #c0c0c0;
}

.top-bar > ul li a:hover:not(.profile-link) {
  background-color: #4e801f;
}

.profile-action .action-menu > a:last-child:first-child {
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
}

/* LOGO-BAR */
.logo-bar {
  position: relative;
  margin: 14px 30px 0 112px !important;
  height: 60px;
}

.logo-bar .logo {
  position: relative;
  height: 32px;
  width: 171px;
  display: block;
  float: left;
  background-repeat: no-repeat;
}

.logo-bar .logo.en {
  background-image: url('../../../../src/assets/images/manulife-logo-en.jpg');
}

.logo-bar .logo.fr {
  background-image: url('../../../../src/assets/images/manulife-logo-fr.jpg');
}

.navbar-nav > li {
  float: left;
}

.navbar-right {
  float: right;
}

.nav {
  padding-left: 0;
  list-style: none;
}

.tool-bar-error {
  position: absolute !important;
  right: 0;
  float: right;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.tool-bar {
  position: absolute !important;
  right: 0;
  float: left;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.tool-bar.ie,
.tool-bar-error.ie {
  right: 75px !important;
}

.tool-bar .mwi-button,
.tool-bar-error .mwi-button {
  font-size: 14px;
  padding: 0 15px;
  height: 44px !important;
  display: block;
  float: left;
}

.tool-bar .mwi-button:hover,
.tool-bar-error .mwi-button:hover {
  color: #005844 !important;
  background-color: white !important;
}

.tool-bar .mwi-button:not(:last-child),
.tool-bar-error .mwi-button:not(:last-child) {
  margin-right: 24px;
}

.skip-main {
  padding-left: 50px !important;
}

.view-report {
  width: 165px;
  padding: 12px 12.7px 13px 13px !important;
}

.paddingLeft70 {
  margin-left: 70px !important;
  padding-left: 70px;
}

@media (max-width: 1024px) {
  .top-bar > ul li a {
    font-size: 14px;
  }
  .top-bar > ul.navbar-left li a {
    padding: 12px 16px;
  }
  .top-bar > ul.navbar-right li a {
    padding: 12px 12px;
  }
  .top-bar .profile-name .profile-link {
    font-size: 14px;
  }
  .top-bar .profile-select {
    width: 170px;
  }
  .top-bar .profile-select .select-dropdown {
    font-size: 14px;
  }
}

.header-label {
  font-weight: 300;
  color: #ffffff;
  margin-top: 4px;
  padding-left: 8px;
}

.header-label-selected {
  font-weight: 600;
  color: #34384b;
  padding-left: 8px;
}
