.ReactModal__Content.change-product-wrapper {
  position: relative;
  top: 333px !important;
  left: 50px;
  right: 50px;
  bottom: 460px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 340px !important;
  height: auto !important;
}
.ReactModal__Content.change-product-wrapper.fr.duplicate {
  width: 340px !important;
  height: auto !important;
}
.ReactModal__Content.change-product-wrapper.fr.not-duplicate {
  width: 340px !important;
  height: auto !important;
}
.ReactModal__Content.change-product-wrapper.en.duplicate {
  width: 340px !important;
  height: auto !important;
}
.ReactModal__Content.change-product-wrapper.en.not-duplicate {
  width: 340px !important;
  height: auto !important;
}
.change-product-wrapper .title-model {
  height: 47px;
  padding: 18px 46.1px 18px 19px !important;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  color: #111212;
  background-color: #ffffff;
  border-bottom: 0px
}
.change-product-wrapper .row-model {
  padding-left: 18px;
  padding-right: 18px;
  /* padding-bottom: 7px !important; */
  margin-bottom: 0 !important;
  margin-top: 0px !important;
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
}
.change-product-wrapper .row-model .error {
  margin-top: 16px !important;
}

.row-model .dropdown-container {
  width: 100%;
  height: 36px;
  border-radius: 3px;
}

.modal-footer {
  margin: 18px 19px 17px 17px !important;
}

body .cancel-modal {
  margin-right: 14px !important;
  position: relative;
  height: 44px !important;
  display: block;
  background-color: #ffffff !important;
  color: #4e803b !important;
  font-size: 14px !important;
  border: 2px solid #4e803b !important;
  border-radius: 3px;
  font-family: OpenSans-SemiBold, sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

body .save-modal {
  margin-left: 14px !important;
  position: relative;
  height: 44px !important;
  width: 100px;
  display: block;
  font-family: OpenSans-SemiBold, sans-serif;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.dropdown-text {
  padding-left: 14px;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.dropdown-icon{
  padding-right: 18px;
}

div.no-margin {
  padding-right: 0 !important;
}

div.remove-label-margin {
  margin-bottom: 0 !important;
}

.change-error-box {
  margin-left: 17px !important;
  margin-top: 10px !important;
  padding-right: 37px !important;
}
