.deposit-option-label {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #00683c;
}

.deposit-option-wrapper {
  margin-top: 24px;
}

.deposit-option-label-padding {
  padding-top: 10px;
  /* float: right; */
}

.deposit-option-help > .material-icons {
  font-size: 17px !important;
}
.deposit-option-help {
  margin-top: 27px;
  margin-left: 6px;
}

.deposit-option-help .p-overlaypanel {
  width: 300px;
  z-index: 999 !important;
}
