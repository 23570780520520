.mwi-select-btn {
  color: #555 !important;
  background-color: #fff !important;
  font-weight: 400 !important;
  border: 1px solid #e0e3e2 !important;
  width: 47%;
  height: 100%;
  vertical-align: middle !important;
}

.mwi-select-btn:focus {
  /* Makes it so focus outline is not cut off by overlapping buttons */
  z-index: 10000;
}

.mwi-select-btn:hover:not(.mwi-selectedOption) {
  border-color: silver !important;
  background-color: #eee !important;
  color: #212121 !important;
}

.mwi-select-optionL-btn {
  margin-right: 0 !important;
  display: block;
  float: left;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 14px !important;
}

.mwi-select-optionR-btn {
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: block;
  float: left;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-size: 14px !important;
}

.mwi-selectedOption,
.mwi-selectedOption:hover {
  color: #111212 !important;
  border: 1px solid #e0e3e2 !important;
  background: #e0e3e2 !important;
  font-size: 14px !important;
}
