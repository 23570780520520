@import '../core/styles/primary-fonts.css';
/* CONTENT */

#landing-main-content {
  font-size: 14px;
  margin: 24px 20px !important;
}

#product-select-wrapper-id {
  padding: 0;
  height: auto;
  width: 300px;
}

#product-select-wrapper-id .title-model {
  height: 47px;
  padding: 13px 13px 13px 20px !important;
  font-size: 14px;
  font-weight: normal;
  background-color: #f7faf9;
  color: #00693c;
  width: 100%;
}

#product-select-wrapper-id div div .row-model {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-end;
  font-size: 14px;
}

#product-select-wrapper-id div div .productSelectContent {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;
  padding: 15px 20px 10px !important;
}

.cancelProductSelectBtn {
  margin-right: 7px !important;
  padding: 0 11px 0 11px;
}

.continueProductSelectBtn {
  padding: 0 3px 0 3px;
}

.start-wrapper {
  position: relative;
  width: 100%;
  padding-left: 3px;
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.product-selector-content a {
  text-decoration: none;
  color: inherit;
}
.product-selector-content a:active,
.product-selector-content a:hover,
.product-selector-content .active {
  text-decoration: underline;
  text-decoration-color: #00693c;
  font-weight: bold;
  color: inherit;
}

.product-selector-content-div {
  width: 100%;
}

.product-select-radio-div {
  padding: 0 0 0 7px;
}

.welcome-note h2 {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 22px;
}
.home-header h3 {
  font-weight: 600;
  font-size: 14px;
}

.start-arrow {
  position: absolute;
  top: 5px;
  left: 10px;
  color: white;
  font-size: 20px !important;
  z-index: 10;
  pointer-events: none;
}
.concept-note > span {
  font-style: italic;
}
.product-description {
  margin-top: 10px;
}
.product-select-note {
  margin-top: 26px;
  font-weight: bold;
}
.product-selector-content {
  margin: 16px 4px;
}
.landing-right-container {
  margin-left: 8.333%;
}
.landing-image {
  width: 100%;
  height: 227px;
}
.landing-child-wrapper {
  margin-bottom: 24px;
}
.product-header {
  margin-bottom: 16px;
}
.product-header > h2 {
  font-size: 22px;
  font-weight: 600;
}
.margin-top16 {
  margin-top: 16px;
}
.margin-top32 {
  margin-top: 32px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-bottom10 {
  margin-bottom: 10px;
}
.margin-bottom20 {
  margin-bottom: 20px;
}
.accordion-border {
  border: 1px solid #979797;
}
.start-button-group .mwi-button.with-padding {
  padding: 0 20px 0 20px;
}
.start-button-group button {
  height: 44px;
}
.whats-new-panel .title-group {
  left: 10px;
}
.important {
  color: #005980;
  font-family: 'OpenSans-SemiBold', serif;
  padding: 12px;
  padding-top: 13px;
  border: solid 1px #005980;
  margin-bottom: 16px;
}
.important-feedback {
  font-family: 'OpenSans-Bold', serif;
}

.welcome {
  color: #00693c;
  padding-top: 30px;
  padding-bottom: 20px;
}

.landing-note {
  font-style: italic;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
}
.whats-new h2 {
  font-size: 28px;
  font-weight: bold;
  margin: 20px 0;
  color: #555858;
}

.whats-new.hidden {
  display: none;
}

.details-title {
  font-size: 20px;
  color: #555858;
}
.details {
  font-size: 14px;
  margin-bottom: 20px;
}
.details-cell {
  display: table-cell;
  width: 50%;
  text-align: left;
  vertical-align: top;
}
.details-cell:first-child {
  border-right: 1px solid #c0c0c0;
  padding-right: 20px;
}
.details-cell:last-child {
  padding-left: 50px;
}
.details-list {
  padding-left: 2em;
  padding-top: 10px;
}

.start-btn {
  font-size: 14px !important;
  font-weight: 500;
  color: #ffffff;
}

/* custom & quick mode selection */
/* .mode-selection {
  font-size: 15px;
} */
.mode-selection .mode {
  margin-bottom: 20px;
}
.mode > div {
  position: relative;
}
/* .mode > div:first-child {
  padding-right: 40px !important;
} */
.mode .mwi-widget {
  width: 100% !important;
}
.mode .mwi-button {
  position: absolute;
  top: 60px;
}
.mode-selection .title h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #555858;
}

.login {
  position: relative;
  border-left-width: 1px;
  border-left-color: rgba(211, 211, 211, 0.836);
  border-left-style: solid;
}
.mode .mwi-button,
.login .mwi-button {
  margin-top: 20px;
}
.whats-new-panel {
  margin-bottom: 10px;
}
.product-text-top-margin {
  margin-top: 16px;
}
#landing-home {
  margin-bottom: 16px;
}
#landing-home > h3 > span {
  border-bottom: 3px solid #00693c;
}
@media (max-width: 1400px) {
  .p-col-4.login {
    padding-left: 20px !important;
  }
}
@media (max-width: 1024px) {
  .p-col-4.login {
    padding-left: 10px !important;
  }
  #landing-main-content.fr {
    margin-bottom: 150px;
  }
  /* .login .mwi-button {
    bottom: 77px;
  } */
}

.ftrc-landing-box {
  border: solid 1px #e9f0ed;
  background-color: #e9f0ed;
}

.ftrc-landing-contents {
  padding: 20px 18px;
}
.ftrc-landing-title {
  font-size: 14px;
  font-family: 'OpenSans-Bold', serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #004427;
}
.ftrc-warning {
  font-size: 16px;
  color: #a95302;
  display: inline-flex;
}
.ftrc-warning-icon {
  margin-right: 12px;
}

.logoHeader-Height {
  height: 80px;
}

.url-link {
  font-family: OpenSans-Regular, serif;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #00693c;
  text-decoration-color: #00693c;
  cursor: pointer;
  color: #004427;
}

.message.error {
  color: #d70909;
}

button.mwi-selectedOption {
  cursor: default;
}
.align-self-end {
  align-self: flex-end;
  padding: 0;
}

.warning {
  font-size: 14px;
  color: #a95302;
}

.warning-icon {
  margin-right: 5px;
}