#heading1 {
    padding-bottom: 0.5em;
}

#content1 {
    padding-bottom: 1em;
}

#content2 {
    padding-bottom: 1em;
}

#button_StaySignedIn {
    margin-right: 2em;
}