.mwi-persona {
  position: relative;
  font-size: 12px;
}
.mwi-persona .icon {
  display: inline-block;
  text-align: center;
  margin-right: 9px;
  font-weight: bold;
  font-size: 13px;
}
.mwi-persona .material-icons {
  font-size: 32px;
}
.mwi-persona .icon.name-init {
  background-color: #2d2d2d;
  padding: 5px 0;
  color: white;
  width: 32px;
  height: 32px;
  border: 1px solid #c0c0c0;
  border-radius: 50%;
}
.mwi-persona .info {
  font-size: 13px;
  font-weight: bold;
}
