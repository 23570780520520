@import './core/styles/primary-fonts.css';
@import './core/styles/manupictos-icon.css';
@import './core/styles/material-icons.css';
@import './core/styles/mwi-theme.css';
@import './core/styles/mwi-tab-navs.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

/* You can add global styles to this file, and also import other style files */
body {
  padding: 0;
  background-color: white;
  font-family: 'OpenSans-Regular', sans-serif;
}
