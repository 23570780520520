.modalContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
