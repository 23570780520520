.dialog-wrapper {
  height: 100%;
  width: 100%;
}

.dialog-content {
  padding-left: 24px !important;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.dialog-title {
  height: 47px;
  padding: 13px !important;
  font-size: 16px;
  background-color: #f7faf9;
  color: #00693c;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.wide-60 {
  width: 60%;
}

.wide-35 {
  width: 35%;
}

.right-padding {
  padding-right: 25px !important;
}

.footer-buttons {
  display: flex;
  padding-right: 25px;
  margin-top: 24px;
}
