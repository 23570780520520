#marginal-tax-wrapper > .marginal-tax-rate {
  margin-top: 0;
  margin-bottom: 0;
}
#marginal-tax-wrapper > .marginal-tax-rate > .ui-input {
  width: 100%;
}
#marginal-tax-wrapper > .marginal-tax-rate > .ui-input > .number-data {
  width: 100% !important;
}
#personal-dividend-wrapper {
  /* margin-right: 1.3%; */
}
#tax-rates-btn {
  margin-top: 24px;
}
#marginal-tax-wrapper {
  /* margin-right: 2.6%; */
}
