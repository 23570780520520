.client {
  position: relative;
  margin-bottom: 10px;
}
.client-container {
  position: relative;
  margin-bottom: 0px !important;
  margin-top: 24px !important;
}
.client .p-col-12 {
  margin-bottom: 10px !important;
}
.client .p-grid {
  margin-bottom: 15px;
}
/* .client .edit-rating {
  margin-top: 20px;
  margin-left: 10px;
  width: 90% !important;
} */

.hs-help > .material-icons {
  font-size: 16px !important;
}

.hs-help {
  /* position: absolute; */
  z-index: 999;
  position: relative;
  right: 15px;
}

.hs-help.second {
  z-index: 990;
}

/* .hs-help.fr {
  right: 12.5% !important;
} */

.hs-help .p-overlaypanel {
  width: 400px;
  z-index: 99999 !important;
}

/* French text is too long so display the tooltip on the left side */
.hs-help.fr .p-overlaypanel {
  width: 500px !important;
  left: -482px !important;
}

/* Above is different for PerformaxGold */
.PerformaxGold + .hs-help.fr .p-overlaypanel {
  width: 500px !important;
  left: 0 !important;
}

/* Business Term */
.BusinessTerm + .hs-help.fr .p-overlaypanel {
  width: 500px !important;
  left: 0 !important;
}

/* Family Term */
.FamilyTerm + .hs-help.fr .p-overlaypanel {
  width: 500px !important;
  left: 0 !important;
}

.help-indent-en {
  display: inline-block;
  text-indent: -6.3em;
  padding-left: 6.3em;
}

.help-indent-fr {
  display: inline-block;
  text-indent: -7.1em;
  padding-left: 7.1em;
}

/* .ratings {
  margin-left: 16px !important;
} */

@media (max-width: 1280px) {
  /* French text is too long so display the tooltip on the left side */
  .hs-help.fr .p-overlaypanel {
    left: -300px !important;
  }
}
@media (max-width: 1024px) {
  .hs-help .p-overlaypanel {
    left: -382px !important;
  }
  /* French text is too long so display the tooltip on the left side */
  .hs-help.fr .p-overlaypanel {
    left: -400px !important;
  }
}
