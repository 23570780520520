.ReactModal__Content.progress-dialog-wrapper {
  width: 405px !important;
  height: auto !important;
  position: relative;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.progress-dialog-wrapper .row-model {
  padding-right: 24px;
}
