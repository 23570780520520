.custom-vitality-wrapper {
  position: relative;
  background-color: white;
}
.custom-vitality-wrapper .wrapper-title {
  height: 48px;
  font-size: 16px;
  padding: 10px;
  padding: 14px 24px 0px 24px;
  color: #00693c;
  background-color: #f7faf9;
  border-bottom: solid 1px #979797;
}

.custom-vitality-help {
  padding: 24px;
  font-size: 14px;
}

.custom-vitality-wrapper .header-wrapper {
  height: 44px;
  background-color: #f7faf9;

  width: 100%;
  border: solid 1px #979797;
}
.custom-vitality-wrapper .mwi-cell {
  font-size: 16px !important;
  width: '';
}
.footer-wrapper .mwi-cell:first-child {
  text-align: left;
}
.footer-wrapper .mwi-cell:last-child {
  text-align: right;
}
.wrapper .mwi-cell:last-child {
  width: 40%;
}
.custom-vitality-wrapper .header-wrapper .mwi-cell:last-child {
  width: 43.35% !important;
}
/* .header-wrapper.wrapper.mwi-table .mwi-cell {
   border-right: solid 1px #979797;
} */
.header-wrapper.wrapper.mwi-table .mwi-cell:last-child {
  border-right: none;
}
.duration-list-wrapper {
  margin: 0 24px;
  height: calc(50vh);
  overflow: hidden;
}
.duration-list-wrapper .m {
  margin: 0 24px;
  height: 430px;
  /* overflow: auto; */
}
.duration-list {
  display: block !important;
  overflow: auto;
  height: calc(50vh - 50px);
}
.custom-vitality table .mwi-input {
  border-radius: 0;
}
.duration-list .mwi-row .mwi-input,
.duration-list .mwi-row select {
  border-top: none !important;
  border: solid 1px #979797;
  height: 44px;
}
.duration-list .mwi-row .mwi-input {
  text-align: center;
  border-right: none !important;
}
.mwi-row .mwi-cell:hover > input,
.mwi-row .mwi-cell .mwi-dropdown-container:hover > select {
  background-color: #eeeeee;
}
.duration .mwi-dropdown-container .material-icons {
  top: 7px !important;
  right: 10px !important;
}
.mwi-row.duration {
  height: 44px;
}
.custom-vitality-wrapper .footer-wrapper {
  padding: 24px;
  height: 40px;
}
.footer-wrapper button {
  font-size: 14px !important;
}
.footer-wrapper .filldown-btn.en {
  width: 50%;
}
.footer-wrapper .filldown-btn.fr {
  width: 100%;
}
.status-dropdown {
  float: none;
  width: 100%;
}
.custom-vitality table {
  width: 100%;
}
.ReactModal__Content.custom-vitality {
  position: relative;
  width: 540px !important;
  height: 500px !important;
  margin: 0 auto !important;
  padding: 0 !important;
}
.p-col-2.statuses {
  width: 15% !important;
}
.edit-status {
  margin-top: 21px;
  /* width: 90% !important; */
}
.vitality-button {
  top: 270px !important;
  width: 500px;
  height: 200px;
}
@media (max-width: 1024px) {
  /* .form-wrapper {
    padding: 20px 10px !important;
  } */
  .custom-vitality-wrapper .header-wrapper .mwi-cell:last-child {
    width: 39.45% !important;
  }
}
