.business-card-row .business-card-lbl {
  color: #111212;
  height: 1.1875em;
  font-family: OpenSans-Regular, serif;
  font-size: 0.875em;
  margin: 4px 4px 7px 4px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
}

#help-icon {
  padding-left: 0em;
  margin: 3px 12px 0px 4px;
}

.business-card-help .p-overlaypanel {
  width: 18.75em;
  color: #111212;
  z-index: 999;
}

.business-card-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.dd-profile {
  height: 2.25em;
  z-index: 10;
}

.p-col-9 {
  width: 340px;
}
