.cpr-wrapper {
  margin-top: 10px;
  /* padding-left: 15px; */
}
.cpr-wrapper .rider-label {
  margin-bottom: 24px;
  font-weight: 500;
  color: #4a4a4a;
  font-size: 18px;
}
.check-wrapper .ui-chkbox {
  margin-right: 10px;
}
.cpr-wrapper .mwi-icon.add-child {
  /* top: 5px; */
  padding-right: 5px;
}
.cpr-wrapper .edit-rating {
  margin-top: 22px !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.cpr-wrapper .udm-id {
  top: 0;
  left: 60px;
}
.cpr-wrapper .delete-wrapper {
  display: inline-block;
  position: absolute;
  top: 22px !important;
  /* right: 40px !important; */
  /* width: 115px; */
  padding-left: 5px;
  background-color: white;
  color: #4a4a4a !important;
  cursor: pointer;
}
.cpr-wrapper .delete-wrapper span {
  position: absolute;
  top: 5px;
}
.cpr-wrapper .delete-wrapper.fr {
  width: 135px !important;
}
.cpr-wrapper .delete-wrapper .delete-child {
  margin-right: 5px;
  font-size: 18px;
}
/* .cpr-wrapper .p-col-12 {
  margin-bottom: 15px;
} */

.riderChildBorder {
  display: inline-block;
  position: absolute;
  top: 7px;
  width: 100%;
  height: 5px;
  margin-left: 5px;
  border-top: 1px dashed lightgray;
}

/* .childDivider::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 80%;
  left: 10%;
  border-top: 1px solid #c0c0c0;
} */
