.width-25-percent {
  width: 25%;
}
.form-main .p-col-12.no-margin {
  position: absolute;
  top: 5px;
  margin-bottom: 0;
}
#coverage-help {
  margin-top: 30px;
  margin-left: 8px;
}
.form-main .p-inputgroup input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.form-main .p-inputgroup-addon {
  padding-top: 0.36em;
}
/* TAB CSS */
.help-icon-coverage {
  margin-right: 24px;
}
/* style to accomodate change in DOM order for help icon on Coverage-tab */
.right-icon {
  float: right;
  margin-top: 6px !important;
}

body .p-tabview .p-tabview-panels {
  padding: 0.571em 1em;
}

/* TAB CSS END*/
.riders-container:last-child {
  margin-bottom: -24px;
}

.form-main .coverage-client {
  position: relative;
}

.mwi-italic {
  font-style: italic;
  color: red;
}

.solve-wrapper {
  position: relative;
}

.solve-wrapper .help-size {
  position: absolute;
  top: 1px;
  font-size: 18px !important;
  color: black !important;
}

.solve-wrapper .p-chkbox {
  margin-left: 5px;
}
.solve-wrapper .is-multi-cov {
  color: #747474;
}
.solve-wrapper .solve-coverage-check {
  background-color: #747474;
  border: none;
  width: 15px;
  height: 15px;
}
.solve-wrapper .solve-question {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}
.solve-wrapper .solve-question.fr {
  margin-top: -16px;
}
.solve-wrapper .material-check {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  pointer-events: none;
}

.premium-align {
  margin-top: 20px;
  margin-right: 5px;
}
.mwi-input.disabled-amount {
  background-color: #f9f9f9 !important;
}
.premium-amount-container {
  margin-left: -34px !important;
}
.required-field {
  position: relative;
  top: -20px;
  color: #ea0303;
  font-weight: bold;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: -12px;
}

#premiumCheckbox {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 5px;
  vertical-align: middle;
}

.solve-help > .material-icons {
  font-size: 20px !important;
}

.solve-help {
  margin-left: 10px;
  margin-right: 0;
  margin-top: 9px;
}

.solve-help .p-overlaypanel {
  width: 300px;
}

.dividend-option .splitter {
  margin: 24px auto;
}
.dividend-option .switch-to-cash {
  margin-top: 24px;
}
.dividend-option .div-option-label {
  padding-right: 24px !important;
}
.dividend-option .div-option-year {
  padding-left: 8px !important;
}
.dividend-option .div-option-value {
  margin-top: 5px !important;
}

.div-option-to-face-amount {
  padding-left: 24px !important;
}
.par-g-3 {
  width: 20%;
}
.accordion.first-child {
  margin-top: -8px;
}
.accordion.last-child {
  margin-bottom: -25px;
}

/* Add Coverage */
.accordion .title .addCov .title-group i {
  padding-right: 5px;
}

.p-col .change-label {
  display: inline;
  padding-left: 0;
}

.product-font-label {
  color: #111212 !important;
}

.effective-align-Center {
  align-items: center;
  display: flex;
}

.product-label-margin-top {
  margin-top: 2px;
}

.display-flex {
  display: flex;
}

.change-margin-left {
  margin-left: 10px;
}

.p-col .change-label a {
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #004427;
  text-decoration: underline;
  cursor: pointer;
}

div#coverage-product-label {
  margin: 0 0 18px 0;
}

.vitalityplus-helptext-size-en {
  width: 295px !important;
  max-width: 295px !important;
}

.vitalityplus-helptext-size-fr {
  width: 315px !important;
  max-width: 315px !important;
}

@media (max-width: 1024px) {
  .premium-amount-container {
    margin-left: -2px !important;
  }

  /*Fix for riders*/
  /* .coverage-wrapper .p-tabview .p-tabview-nav > li.riderFix.fr{
    width: 25% !important;
    line-height: 0.9 !important;
    white-space: pre-wrap !important;
  }  */

  .top-margin-24 {
    margin-top: 24px !important;
  }

  .solve-help .p-overlaypanel {
    left: auto !important;
    top: auto !important;
  }
}

.dividendContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: last baseline;
  margin-bottom: 10px;
}
