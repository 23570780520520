.highlight {
  color: #db1f00;
}

.bold {
  font-weight: 500;
}

.descriptionText {
  font-size: 14px;
  color: #5e6073;
  font-weight: lighter;
}
