.summary-container {
  width: 100%;
}

.summary-area {
  border: 1px solid #979797;
  background-color: #f7faf9;
  position: relative;
}

.summary-header {
  width: 340px;
  height: 46px;
  background-color: #046138;
  padding: 12px 12px 12px 12px;
  font-size: 14px;
  color: #ffffff;
  font-family: OpenSans-Bold, serif;
  text-transform: uppercase;
}

.summary-header-product {
  width: 340px;
  height: 34px;
  background-color: #046138;
  padding: 0px 12px 0px 12px;
  font-size: 14px;
  color: #ffffff;
  font-family: OpenSans-light, serif;
}

.summary-header-product .mwi-bold {
  font-family: OpenSans-SemiBold, serif;
}

.summary-header-product-parVPFrench {
  width: 340px;
  height: 34px;
  background-color: #046138;
  padding: 0px 12px 0px 12px;
  font-size: 13px;
  color: #ffffff;
  font-family: OpenSans-light, serif;
}

.summary .progress-bar {
  padding-top: 0px;
  margin-top: -10px;
}

.summary .p-grid.no-margin {
  margin-bottom: 0 !important;
}

.summary .main-title {
  padding: 6px 14px 0 0;
}

.summary .main-title.udm-error {
  border-bottom-color: red;
}

.summary .main-title.loading-udm {
  border-bottom: none;
}

.summary .main-title span {
  display: inline-block;
}

.summary .recalculate {
  float: right;
  height: 22px;
  text-align: center;
}

.summary .recalculate a {
  color: #ffffff;
  font-family: OpenSans-Semibold, serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: initial;
  text-decoration: underline;
  cursor: pointer;
}

.summary .refresh {
  margin-top: -2px;
}

.summary .initial,
.summary .total-premium {
  margin-bottom: 8px;
}

.summary .content .sce-premium {
  font-size: 14px;
  color: black !important;
  margin-bottom: -10px !important;
  padding: 12px 12px 0px 12px;
}

.summary .content .sce-premium .amount span {
  font-weight: normal;
}

.summary .content .sce-premium .initial {
  font-weight: normal;
}

.summary .content .total-premium {
  font-weight: bold;
  font-family: OpenSans-Bold, serif;
}

.summary .freq-premium {
  margin-bottom: 10px;
  min-height: 36px;
}

.summary .message-box {
  padding: 12px 12px 8px 12px;
}

.message-box .message.error {
  color: #d70909;
  font-size: 13px;
}

.message-box i.msg-icon {
  position: relative;
  top: 3px;
  float: left;
  font-size: 15px;
  margin-right: 3px;
}

.message-box .message.warning {
  color: #a95302;
  font-size: 13px;
}

.message-box .message {
  margin-bottom: 5px;
}

.summary .info {
  font-weight: 600;
}

.summary .label {
  font-size: 13px;
  font-weight: normal;
  color: #4a4a4a;
  text-align: left;
}

.summary .par-label {
  font-size: 14px;
  color: black !important;
  margin-bottom: -10px !important;
}

.summary .par-amount {
  font-size: 14px;
  font-weight: normal;
  color: black !important;
  text-align: right;
  margin-bottom: -10px !important;
}

.summary .bronze-minimum-label {
  font-size: 13px;
  font-weight: normal;
  color: #4a4a4a;
  text-align: left;
  display: flex;
  align-items: center;
}

.summary .body-label {
  font-size: 13px;
  font-weight: normal;
  color: #4a4a4a;
  text-align: left;
}

.summary .sub-label {
  padding-left: 24px;
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  text-align: left;
}

.summary .total-amount {
  text-align: right;
  margin-bottom: 8px;
}

.summary .initial-amount {
  font-weight: normal;
  text-align: right;
  margin-bottom: 8px;
}

.summary .total-frequency {
  font-size: 12px;
  font-weight: normal;
  text-align: right;
}

.summary .amount {
  font-size: 13px;
  font-weight: normal;
  color: #4a4a4a;
  text-align: right;
  margin-bottom: -100%;
}

.summary .bronze-minimum-amount {
  font-size: 13px;
  font-weight: normal;
  color: #4a4a4a;
  text-align: right;
  margin-bottom: -100%;
  margin-top: 2px;
}

.summary .coverage {
  font-size: 12px;
  padding-right: 12px;
}

.summary .deposit-option,
.summary .rider-group,
.summary .illustration-rates,
.summary .additional-costs .summary .residence {
  padding-right: 12px;
  padding-left: 12px;
  font-size: 13px;
}

.summary .ftrc-option {
  padding-left: 12px;
  font-size: 14px;
}

.ftrc-flex {
  display: flex;
}

.summary .summary-accordion-font {
  font-size: 14px !important;
}

.summary .summary-accordion-no-border {
  border: 0 !important;
}

.summary-help > .material-icons {
  font-size: 17px !important;
  vertical-align: middle !important;
}

.refresh i.material-icons {
  margin-left: 62px;
  transform: rotate(180deg);
  vertical-align: middle;
}

.summary-help {
  margin-left: 12px;
}

.summary-scroll {
  /* overflow-y: auto;
  max-height: calc(100vh - 494px); */
  margin-right: 0px;
}

.summary-scroll .accordion {
  border-left: none;
  border-right: none;
  height: 36px;
  /* this is the equivalent opaque colour for rgb(233, 240, 237) or #e9f0ed
     using the following formula (a is the opacity):
     (r, g, b) {
        var a = 1 - Math.min(r, Math.min(g, b)) / 255;
        return [255 + (r - 255) / a, 255 + (g - 255) / a, 255 + (b - 255) / a, a];
  */
  background-color: rgba(0, 68, 30, 0.08);
}

.summary-accordion-wrapper {
  margin-top: 1px;
}

.summary-scroll .accordion .title {
  top: 8px;
}

.scroll-shadow {
  box-shadow: inset 0 18px 20px -20px black;
}

.summary .scroll-shadow:not(.ios) .coverage,
.summary .scroll-shadow:not(.ios) .deposit-option,
.summary .scroll-shadow:not(.ios) .rider-group,
.summary .scroll-shadow:not(.ios) .illustration-rates,
.summary .scroll-shadow:not(.ios) .additional-costs {
  padding-right: 7px;
}

.summary div[class~='accordion'][class~='folded'] {
  border-bottom: none !important;
}

.withdrawals {
  border-top: 1px solid #c0c0c0;
  background-color: #e9f0ed;
  height: 34px;
  padding-left: 30px;
  padding-right: 24px;
  position: relative;
  font-size: 16px;
  align-items: center;
}

.withdrawals .value {
  font-size: 14px;
  text-align: right;
  color: black;
}

.scroll-shadow .withdrawals {
  padding-right: 7px;
}

.coverage-compare .info {
  font-size: 13px;
  font-weight: bold;
  padding-left: 12px;
  margin-bottom: 8px;
}

.coverage-compare .value {
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 8px;
}

.coverage-compare .view-pdf {
  justify-content: center;
}

.coverage-compare .view-pdf button {
  color: white !important;
  background-color: #4e803b !important;
  height: 36px;
  width: 130px;
}

.coverage-compare .view-pdf button.fr {
  width: 230px;
}

.coverage-compare .view-pdf .p-button-text {
  margin: 0 10px;
  font-size: 14px !important;
}

.coverage-compare .comment {
  font-size: 12px;
  font-style: italic;
  padding: 0px 24px 0px 24px;
  display: flow-root;
  text-align: center;
}

.coverage-compare .compare {
  font-weight: 600;
  margin-bottom: 10px;
}

.coverage-compare .per-month {
  font-weight: 600;
}

.summary-premium-rate-change {
  display: flex;
  padding: 10px 10px 0 10px;
}

.summary-premium-rate-change div {
  font-size: 13px;
  font-family: OpenSans-Regular, serif;
  color: #2d69ff;
  padding-left: 5px;
}

.summary-premium-rate-change i {
  color: #2d69ff;
  margin-top: 2px;
}

@media (max-width: 1024px) {
  .summary-help .p-overlaypanel {
    /* Adjust help positioning for 200% summary sidebar */
    left: auto !important;
    top: auto !important;
    width: 200px;
  }
}

.ml-auto {
  margin-left: auto;
}
