.calculator-header-box {
  border: solid 1px #e9f0ed;
  background-color: #e9f0ed;
  padding: 18px;
  margin-top: 22px !important;
}

.calculator-body-box {
  padding: 18px;
  border: solid 1px #ededed;
  background-color: #ffffff;
}

.calculator-header-label {
  font-family: OpenSans-Bold, serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #004427;
}

.calculator-box2 {
  border: solid 1px #e9f0ed;
  background-color: #e9f0ed;
  padding: 18px;
  margin-top: 22px !important;
}

.calculator-footer-box {
  /* margin-top: 18px; */
  margin-top: 22px;
  margin-bottom: 22px;
  display: flex;
}

.calculator-footer-icon {
  padding-right: 5px;
}

.calculator-footer-icon > i {
  color: #00693c;
}

.calculator-details-list {
  padding-left: 2em;
  padding-top: 12px;
}

.footer-messages {
  font-size: 12px;
}

.footer-disclaimer {
  margin-bottom: 12px;
}

.label-link:hover {
  color: #2f622f;
}
