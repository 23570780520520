.riders-wrapper {
  position: relative;
  width: 100%;
}
.riders-wrapper .header-icon {
  position: absolute;
  padding: 21px 0px 21px 0px;
  width: 9px;
  height: 6px;
  left: 24px;
}
.rider-text-box {
  margin-bottom: 10px;
}

.riders-header {
  font-size: 14px;
  background-color: #f7faf9 !important;
  border-radius: 0 !important;
  margin-top: 0;
  min-height: 48px;
  padding: 13px 0px 13px 0px;
}
.riders-header .ui-accordion-header-text {
  font-size: 16px;
  position: absolute;
  padding-left: 40px;
  font-weight: 600;
  color: #00693c;
}
.riders .hidden-rating {
  visibility: hidden;
}
.riders .ui-accordion-content {
  padding-top: 0 !important;
}
.riders .ui-accordion .ui-accordion-content {
  padding-top: 0 !important;
}
.add-wrapper {
  cursor: pointer;
  font-size: 16px;
  color: #4a4a4a;
}
.ratings-label {
  padding-left: 10px !important;
}

.rider .rider-field {
  position: relative;
  top: -2px !important;
  min-height: 25px;
}
.rider .rating {
  padding-left: 16px !important;
}

.rider.ChildProtectionRider {
  margin-bottom: 15px;
}
.rider .select-client {
  padding-left: 16px !important;
}
.cpr-wrapper {
  border-bottom: 1px solid lightgray;
  padding-left: 16px;
  margin-top: -10px;
}
.rider-checkbox-wrapper {
  position: relative;
  top: 3px;
  outline: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.rider-checkbox-wrapper > label {
  font-size: 13px;
}
.select-client .rider-checkbox-wrapper {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
.rider-checkbox-wrapper .material-icons {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: white;
  pointer-events: none;
}
.rider-checkbox {
  margin-right: 5px;
  vertical-align: middle;
}
.select-client .rider-checkbox-wrapper .rider-checkbox {
  margin-left: 1px;
  margin-bottom: 1px;
}
.rider-checkbox-wrapper .udm-id {
  top: 0;
  right: 0;
}
.rider-checkbox-wrapper-main {
  margin-top: 4px;
}
.rider-checkbox + label {
  margin-top: -4px;
}
.rider-content {
  margin-top: 24px;
}
.rider-content .table-header .header-labels {
  font-size: 14px;
  font-weight: normal;
}
.header-labels-wrapper {
  padding-left: 16px !important;
}

.rider-content .table-header {
  padding: 11px 0px 11px 0px;
}
.rider-content .rider {
  padding: 26px 0 26px 0px;
  min-height: 68px;
  border-top: 1px solid lightgray;
}
.rider-content > div:last-child {
  border-bottom: 1px solid lightgray;
}
.rider-remove-all {
  margin-top: 24px;
  margin-bottom: 0;
}
.rider-remove-all-btn {
  width: 80% !important;
  color: #4e801f !important;
  background-color: white !important;
  border-color: #e0dde1;
}
@media (max-width: 1024px) {
  .rider-remove-all-btn {
    padding: 0px 4px 0px 4px;
    box-sizing: content-box;
    width: 100% !important;
  }
  .select-client input {
    padding: 2px 2px 2px 4px !important;
  }
}
