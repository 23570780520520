.dob-calendar .p-calendar .p-calendar-button {
  color: black;
  background-color: transparent;
  border: 1px solid #a6a6a6;
  border-left: none;
}

.calendar-field {
  width: 100%;
  min-width: 205px;
}

@media (max-width: 1024px) {
  .calendar-field {
    width: 100%;
    max-width: 130px;
    min-width: 50px;
  }
}

.dob-calendar .p-button:enabled:hover {
  background-color: transparent;
  color: black;
  border-color: #a6a6a6;
}

.dob-calendar .p-datepicker table td>span.p-highlight {
  color: #ffffff;
  background-color: #046138 !important;
}

.dob-calendar .mwi-label {
  margin-bottom: 8px;
}

.dob-calendar>.mwi-control-wrapper>.space-right {
  padding-right: 12px !important;
}

.dob-calendar>.mwi-control-wrapper>.mwi-label {
  margin-bottom: 5px;
}

.coverage-div {
  display: flex;
  width: 220%;
}