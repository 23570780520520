.borrowing-type-input {
  color: #262626 !important;
}
.left-text-fields {
  width: 30%;
}
#borrowing-type-container {
  margin-right: 24px;
}
#company-type-container {
  margin-right: 24px;
}
#borrowingType {
  -webkit-text-fill-color: #262626;
  color: #262626 !important;
  opacity: 0.7 !important;
}
