.client .mwi-dropdown-container.mwi-inline {
  margin-left: 20px;
}
.client .label-group {
  position: relative;
  font-weight: 500;
  font-size: 18px !important;
  bottom: 1px;
}
.client .label-group .material-icons,
.childDivider .label-group .material-icons:not(.delete-child) {
  position: relative;
  top: 10px;
  margin-right: 5px;
  color: #4a4a4a;
  font-size: 37px !important;
}
.childDivider {
  margin-top: 10px;
}
.childDivider:last-child {
  margin: 24px 0 24px 0;
}
.childDivider:first-child {
  margin-top: 0px !important;
}
.client .p-col-12 {
  margin-bottom: -10px !important;
}
.client.insured {
  margin-bottom: 0;
}

.client.insured .content {
  display: inline-flex;
  align-items: center;
}

.client .p-col-12 .mwi-label {
  /* Hide label for AODA because we label the dropdown with something else*/
  display: none;
}

.client .Select-placeholder {
  /* AODA contrast fix */
  color: #333 !important;
}

.client .client-selection .Select-placeholder {
  top: -3px;
}
