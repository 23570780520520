/* MWI Theme */
.mwi-widget {
  height: 36px;
  box-shadow: none !important;
  font-family: 'OpenSans-Regular', serif;
  font-size: inherit;
}
.mwi.manupicto {
  opacity: 0.7;
  font-size: 19px;
  margin-right: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
}
.mwi-h25 {
  height: 25px;
}
.mwi-widget:focus,
.focusOutline:focus {
  outline: solid 5px -webkit-focus-ring-color !important;
  outline: solid 2px #7aacfe !important;
}
a:focus,
span:focus {
  outline: solid 5px -webkit-focus-ring-color !important;
  outline: solid 2px #7aacfe !important;
}
.mwi-label {
  color: #111212;
  font-size: 14px;
  margin-bottom: 5px;
}
.mwi-tm {
  position: relative;
  top: -5px;
  font-size: 10px;
}
.mwi-table {
  display: table;
  width: 100%;
}
.mwi-row {
  display: table-row;
}

.mwi-expanded > div {
  display: block !important;
}
.ui-accordion .mwi-expanded {
  max-height: 4000px !important;
  padding-left: 27px;
}
.p-col-1.mwi-control-wrapper {
  width: 10.5%;
}
.p-col-1.mwi-control-wrapper.fr {
  width: 9%;
}
.p-col-1.mwi-control-wrapper.healthstyle.fr {
  width: 12%;
}
.mwi-error-field {
  border-color: red !important;
  border-left-width: 1px !important;
}
.mwi-error-msg {
  display: block;
  color: red;
  font-size: 10px;
  font-style: italic;
}
.mwi-inline {
  display: inline-block !important;
}
.mwi-bold {
  font-weight: 700;
}
.mwi-weight-500 {
  font-weight: 500;
}
.mwi-weight-600 {
  font-weight: 600;
}
.divider {
  width: 60px;
  height: 1px;
  background-color: #979797;
}
.client-splitter {
  margin-bottom: 15px !important;
}
.splitter {
  width: 100%;
  margin: 24px auto;
  border-top: 1px dashed #979797;
  border-bottom: none;
  /* margin-bottom: 10px; */
}
.mwi-float-left {
  float: left;
}
.mwi-float-right {
  float: right;
}
.mwi-icon {
  position: relative;
  font-size: 20px;
  z-index: 1;
  color: #00693c;
  pointer-events: none;
}
.mwi-link {
  padding-top: 16px;
  width: 34px;
}
.mwi-white-only {
  /* border: 1px solid #4e803b !important; */
  color: #4e801f !important;
  background-color: white !important;
}
.mwi-white-only:hover {
  /* border: 1px solid #4e803b !important; */
  opacity: 0.8;
  color: #000 !important;
  color: #4e801f !important;
  background-color: white !important;
}
.mwi-white-button {
  /* border: 1px solid #4e803b !important; */
  color: #4e801f !important;
  background-color: white !important;
}
.mwi-white-button:hover {
  color: white;
  background-color: #00693c;
}
.yellow-bg,
.p-button.yellow-bg {
  color: #111212;
  font-size: 13px;
  background-color: #ffb500 !important;
  border-color: #ffb500 !important;
}
.mwi-hide {
  display: none;
}
.mwi-padding-left24 {
  padding-left: 24px !important;
}
.mwi-padding-left20 {
  padding-left: 20px !important;
}
.mwi-padding-left12 {
  padding-left: 12px !important;
}
.mwi-padding-right20 {
  padding-right: 20px !important;
}
.mwi-padding-right24 {
  padding-right: 24px !important;
}
.mwi-padding-right12 {
  padding-right: 12px !important;
}
.space-right {
  padding-right: 25px !important;
}
.space-right0 {
  padding-right: 0px !important;
}
.space-right11 {
  padding-right: 11px !important;
}
.item-splitter {
  margin-right: 20px !important;
}
.mwi-padding-right10 {
  padding-right: 10px !important;
}
.mwi-padding-top7 {
  padding-top: 7px !important;
}
.mwi-padding-top16 {
  padding-top: 16px !important;
}
.mwi-padding-top24 {
  padding-top: 24px !important;
}
.mwi-padding-top12 {
  padding-top: 12px !important;
}
.mwi-padding-bottom8 {
  padding-bottom: 8px !important;
}
.mwi-padding-bottom16 {
  padding-bottom: 16px !important;
}
.mwi-padding-bottom24 {
  padding-bottom: 24px !important;
}
.mwi-padding-bottom12 {
  padding-bottom: 12px !important;
}
.mwi-margin-top-6 {
  margin-top: -6px !important;
}
.mwi-margin-top0 {
  margin-top: 0px !important;
}
.mwi-margin-top-10 {
  margin-top: -10px !important;
}
.mwi-margin-top24 {
  margin-top: 24px !important;
}
.mwi-margin-top20 {
  margin-top: 20px !important;
}
.mwi-margin-top10 {
  margin-top: 10px !important;
}
.mwi-margin-top18 {
  margin-top: 18px !important;
}
.mwi-margin-bottom0 {
  margin-bottom: 0 !important;
}
.mwi-margin-bottom5 {
  margin-bottom: 5px !important;
}
.mwi-margin-bottom10 {
  margin-bottom: 10px !important;
}
.mwi-margin-bottom14 {
  margin-bottom: 14px !important;
}
.mwi-margin-bottom18 {
  margin-bottom: 18px !important;
}
.mwi-margin-bottom20 {
  margin-bottom: 20px !important;
}
.mwi-margin-bottom24 {
  margin-bottom: 24px !important;
}
.mwi-margin-bottom30 {
  margin-bottom: 30px !important;
}
.mwi-padding-left6 {
  padding-left: 6px !important;
}
.mwi-padding-left4 {
  padding-left: 4px !important;
}
.mwi-margin-right15 {
  margin-right: 15px !important;
}
.mwi-margin-left-24 {
  margin-left: -24px !important;
}
.mwi-margin-left24 {
  margin-left: 24px !important;
}
.mwi-margin-right14 {
  margin-right: 14px !important;
}
.mwi-margin-left20 {
  margin-left: 20px !important;
}
.mwi-margin-right24 {
  margin-right: 24px !important;
}
.mwi-margin-20by0 {
  margin: 20px 0px !important;
}
.mwi-reverse-margin15 {
  margin-left: -19px;
  margin-right: -15px;
}
.p-component,
.p-component button,
.p-component input,
.p-component select,
.p-component textarea {
  font-size: 14px;
}
.ui-dropdown-item {
  font-family: 'OpenSans-Regular', serif !important;
}
.ui-dropdown-trigger {
  border-left: none !important;
}
.mwi-dropdown-container {
  position: relative;
  /* z-index: 100; */
}
.mwi-dropdown-container .trigger-icon {
  position: absolute;
  right: 30px;
  top: 15px;
  font-size: 1.5rem;
  pointer-events: none;
}
.mwi-dropdown-container .material-icons {
  position: absolute;
  top: 9px;
  right: 2px;
  color: #0f3329;
  font-size: 1.5rem;
  pointer-events: none;
  opacity: 0.7;
}
.mwi-dropdown-container .p-disabled ~ .material-icons {
  opacity: 0.3;
}
.mwi-dropdown {
  width: 100% !important;
  background-color: white !important;
}
.mwi-dropdown .Select-control,
.Select-input {
  height: 28px !important;
}
.is-disabled .Select-control {
  opacity: 0.7 !important;
}
.mwi-dropdown .Select-value {
  line-height: 28px !important;
}
.mwi-dropdown .ui-dropdown-trigger {
  padding-top: 5px;
}
.mwi-dropdown .ui-dropdown-label {
  padding: 5px;
  background-color: #ffffff;
}
.mwi-dropdown .ui-dropdown-panel {
  top: 28px !important;
}
.mwi-dropdown .ui-dropdown-panel .ui-dropdown-list {
  padding: 0 !important;
}
.mwi-dropdown .ui-dropdown-panel .ui-dropdown-item {
  padding: 10px !important;
  border-radius: 0;
  height: 35px;
}
.mwi-dropdown .ui-dropdown-panel .ui-dropdown-item.ui-state-highlight {
  border: 1px solid #00693c;
  border-top: none;
  border-bottom: none;
}
.mwi-dropdown.button-type .ui-dropdown-panel .ui-dropdown-item {
  padding: 0 !important;
  margin: 0 !important;
}
.mwi-dropdown .ui-dropdown-item button {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  border: none;
  text-align: left;
  font-family: inherit;
  background-color: transparent;
}
.mwi-dropdown .ui-dropdown-item button:disabled {
  background-color: lightgray;
}
.mwi-dropdown .ui-dropdown-item.ui-state-highlight button {
  background-color: #4e803b;
  color: white;
}
.ui-dropdown .ui-dropdown-label:focus {
  border-color: blue !important;
}
.udm-id {
  position: absolute;
  padding: 0 5px;
  font-size: 12px;
  background-color: yellow;
  border-radius: 10%;
  border: 1px dashed red;
}
/* Input text */
.mwi-input {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 7px 2px 7px 14px !important;
  border: 1px solid #e0e3e2;
  border-radius: 3px;
  background-color: #ffffff;
  font-size: 15px;
  color: #111212;
}
.mwi-input.number-data {
  text-align: left;
}
.mwi-mandatory-ind {
  color: #ea0303;
}

.mwi-cell {
  display: table-cell;
  width: 25%;
  text-align: center;
  vertical-align: middle;
}
.mwi-cell .mwi-input {
  border-radius: 0;
}
.mwi-cell .select-dropdown {
  border-radius: 0;
}

.ui-state-highlight {
  background-color: #4e803b !important;
  border-color: #4e803b !important;
}
.p-inputtext {
  padding: 0;
  color: #4a4a4a !important;
}
.p-inputtext:disabled {
  color: black !important;
}
.ui-selectbutton {
  width: 100%;
}
.ui-selectbutton > .p-button {
  padding: 7px;
  width: 50%;
  height: 100%;
}
.ui-selectbutton > .p-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.ui-selectbutton > .p-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ui-selectbutton .p-button.ui-state-active {
  color: #111212;
  border: 1px solid #c0c0c0;
  background: #c0c0c0;
}
.p-button.align {
  margin-top: 21px;
  /* width: 90% !important; */
}
.p-disabled {
  opacity: 0.7 !important;
}
.p-disabled label,
.p-disabled .ui-dropdown-trigger {
  background-color: #e0e0e0 !important;
  font-weight: bold !important;
  color: black !important;
}
.p-button .p-button-text {
  line-height: 1;
}
.ui-chkbox-box.ui-state-active,
.ui-radiobutton-box.ui-state-active {
  border: 1px solid #00693c !important;
  background: #00693c !important;
  border-radius: 0 !important;
}
.p-button-text-only .p-button-text {
  padding: 0 !important;
}
.ui-widget-overlay {
  z-index: 1200 !important;
}
.ui-dialog {
  z-index: 12001 !important;
}

.p-col {
  padding: 0px;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12,
.p-lg-4,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-8 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.p-grid {
  margin-left: 0;
  margin-right: 0;
}

.p-grid-no-margin {
  margin-top: 0;
}

.p-grid-no-margin-all {
  margin: 0;
}

.p-grid-no-margin-side {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 20px;
}

.mwi-w100 {
  width: 100% !important;
}

.errorBorder {
  border-color: red;
}

.mwi-w99 {
  width: 99% !important;
}
.mwi-w95 {
  width: 95% !important;
}
.mwi-w93 {
  width: 93% !important;
}
.mwi-w90 {
  width: 90% !important;
}
.mwi-w85 {
  width: 85% !important;
}
.mwi-w88 {
  width: 88% !important;
}
.mwi-w89 {
  width: 89% !important;
}

.mwi-w80 {
  width: 80% !important;
}
.mwi-w75 {
  width: 75% !important;
}
.mwi-w70 {
  width: 70% !important;
}
.mwi-w65 {
  width: 65% !important;
}
.mwi-w60 {
  width: 60% !important;
}
.mwi-w50 {
  width: 50% !important;
}
.mwi-w40 {
  width: 40% !important;
}
.mwi-w45 {
  width: 45% !important;
}
.mwi-w30 {
  width: 30% !important;
}
.mwi-w35 {
  width: 35% !important;
}
.mwi-w25 {
  width: 25% !important;
}
.mwi-w20 {
  width: 20% !important;
}
.mwi-w17 {
  width: 17% !important;
}
.mwi-w15 {
  width: 15% !important;
}
.mwi-w10 {
  width: 10% !important;
}

.mwi-spin {
  -webkit-animation: mwi-spin 1.1s infinite linear;
  animation: mwi-spin 1.1s infinite linear;
}
.mwi-pulse {
  -webkit-animation: mwi-spin 1s infinite steps(8);
  animation: mwi-spin 1s infinite steps(8);
}
.mwi-big-title-bar {
  padding: 10px 25px;
  background-color: #16231c;
  background-color: #00693c;
  height: 76px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.mwi-big-title-bar > span:first-child {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

.mwi-lightGreen {
  color: #00693c;
}

.mwi-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  background: #666666;
  opacity: 0.3;
}

.mwi-progress-spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 10001;
}

.p-progressbar {
  height: 5px !important;
}
.mwi-progress-bar {
  margin-top: 0 !important;
  background-color: green !important;
  border-radius: 0;
  z-index: 100001;
}
.mwi-progress-bar.backdrop {
  position: absolute;

  width: 90%;
  border: solid 1px #979797;
}
.p-progressbar .p-progressbar-value {
  border: 5px solid white !important;
  background: green !important;
  border-radius: 0 !important;
}

/* Remove underline in error title dialog window */
.title-border {
  border-bottom: 0px !important;
}
.title-model {
  border-bottom: solid 1px #979797;
}
.ReactModal__Content {
  top: 50% !important;
  transform: translateY(-50%);
  width: 396px;
  height: 307px;
  margin: 0 auto !important;
  padding: 0 !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.ReactModal__Overlay {
  z-index: 100001;
  background-color: rgba(155, 155, 155, 0.75) !important;
}
body.ReactModal__Body--open {
  overflow: hidden;
}

.oneflex {
  flex: 1;
}
.twoflex {
  flex: 2;
}
.fiveflex {
  flex: 5;
}
.flexdisplay {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flexdisplayCenter {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

input[type='checkbox'] {
  transform: scale(1.2);
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
}

.button-width {
  width: 65% !important;
}

@-webkit-keyframes mwi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mwi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@media (max-width: 1024px) {
  .button-width {
    width: 85% !important;
  }
  .mwi-md-w75 {
    width: 75% !important;
  }

  .p-col-1.mwi-control-wrapper,
  .p-col-1.mwi-control-wrapper.fr {
    width: 6%;
  }
  .p-col-1.mwi-control-wrapper.healthstyle,
  .p-col-1.mwi-control-wrapper.healthstyle.fr {
    width: 15%;
  }

  .ReactModal__Content {
    top: 50% !important;
    transform: translateY(-50%);
  }
}
.p-overlaypanel {
  top: auto !important;
  left: auto !important;
  font-weight: normal;
  font-size: 13px !important;
  z-index: 999 !important;
  width: 200px;
}

/* body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
} */
