.wrapper {
  /* margin-bottom: 30px; */
  overflow: hidden;
  padding: 0 0 24px 16px;
}

.wrapper .rider-label {
  margin-bottom: 24px;
  font-weight: 500;
  color: #4a4a4a;
  font-size: 18px;
}

.wrapper .udm-id {
  top: 0;
  left: 60px;
}

.payor-ratings {
  top: 0;
  width: 16% !important;
}

@media (max-width: 1024px) {
  .payor-ratings {
    width: 18% !important;
  }
}
