.report-wrapper {
  top: 140px;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-bottom: 40px;
  padding: 0 20px;
  /* margin-top: 50px; */
}
.report-wrapper .back {
  margin: 10px 20px;
}
.report-wrapper .sub-wrapper {
  border: 1px solid lightgray;
  margin-bottom: 20px;
  display: flex !important;
}
.report-wrapper button {
  padding: 0 4px;
}
.report-wrapper .section {
  padding: 28px 16px 24px 24px !important;
}
.report-wrapper .section:last-child {
  padding-right: 7% !important;
}

.help-icon > .material-icons {
  font-size: 17px;
}

.report-options-container .label,
.standalone-reports .label {
  margin-bottom: 15px;
  font-size: 20px;
}
.report-options-container .p-grid {
  margin-bottom: 24px;
}
.report-options-container .mwi-label {
  font-size: 14px;
}
.report-options-container .sub-label {
  color: #00693c;
  font-size: 16px;
  font-weight: bold;
}
.report-option-section {
  border-right: 1px solid lightgray;
}
.report-option {
  margin-bottom: 14px !important;
}
.prepared-for {
  padding-top: 14px;
}
.report-pages {
  padding-top: 10px;
}
.profile-button {
  margin-top: 20px;
}
.profile-button i {
  position: absolute;
  top: 5px;
  left: 10px;
  color: white;
  font-size: 20px;
}
.profile-button i:hover {
  color: white;
}
.profile-button .p-button-text {
  position: relative;
  left: 10px;
}
button.pages {
  margin-right: 15px;
}

.report-options-container .select-container .mwi-label > label {
  vertical-align: sub;
}
.report-options-container .select-dropdown {
  font-family: 'OpenSans-Regular', sans-serif;
}
.report-options-container .mwi-dropdown-container {
  height: 25px;
  width: 100px;
}
.report-options-container .mwi-dropdown-container .material-icons {
  top: 9px !important;
}
.report-options-container .report-page-option {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.pages .pageoption-checkbox-wrapper {
  width: 50%;
  padding-right: 8px;
}

.opens-in-new-tab {
  color: #00693c;
}

.select-all.en {
  width: 18%;
}
.select-all.fr {
  width: 23%;
}

.option-ur.en {
  width: 36%;
}
.option-ur.fr {
  width: 27%;
}

.option-td {
  width: 22%;
}

.option-cd.en {
  width: 23%;
}
.option-cd.fr {
  width: 28%;
}

.add-edit-delete-link {
  font-family: OpenSans-Regular, serif;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #00693c;
  text-decoration-color: #00693c;
  cursor: pointer;
  color: #004427;
  margin-left: 10px;
  padding-left: 2px;
}

.link-container {
  margin: 0 0 -10px -20px;
}

.ReactModal__Content.add-profile-wrapper {
  height: 540px !important;
  width: 500px !important;
  background-color: #ffffff;
}

@media (max-width: 1024px) {
  .profile-button i {
    left: 5px;
  }
  .report-wrapper .section:last-child {
    padding-right: 7% !important;
  }
}

.right-margin-4px {
  margin-right: 4px;
}
