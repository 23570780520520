.form-wrapper {
  position: relative;
  padding: 0 0 15px 0 !important;
  margin-bottom: 9px;
  margin-top: 16px;
}

.coverage-wrapper {
  width: calc(100% - 364px);
}

.summary-wrapper {
  right: 0;
  left: 24px;
  height: 100%;
  clear: right;
  width: 340px !important;
}

.spreadsheet-wrapper {
  width: 100%;
}

.sidebarSummaryWrapper .summary {
  width: 100% !important;
  margin-left: 0 !important;
  background-color: #ececec;
}

.sidebarContainer {
  padding: 0 !important;
  overflow-y: auto !important;
  z-index: 200000 !important;
  background-color: #ececec !important;
}

.ui-sidebar-mask {
  display: none;
}

.sidebarShowBtnError {
  position: fixed;
  right: 0;
  top: 30px;
  z-index: 100000;
  font-size: 13px !important;
  margin-top: 4px;
  margin-right: -34px;
}

.sidebarShowBtnError button {
  padding: 5px;
  border-radius: 3px;
}

.sidebarShowBtn {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;
  font-size: 13px !important;
  margin-top: 22px;
  margin-right: -30px;
}

.sidebarShowBtnError .material-icons {
  position: inherit !important;
  margin-left: 3px;
  vertical-align: sub;
  font-size: 16px !important;
  color: red;
}

.verticalBtn {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -moz-transform: rotate(-90deg);
  /* Firefox */
  -webkit-transform: rotate(-90deg);
  /* Safari and Chrome */
  -o-transform: rotate(-90deg);
  /* Opera */
}

.sidebarHideBtn {
  position: fixed;
  right: 0;
  bottom: -2px;
  margin-right: 8em;
  font-size: 13px !important;
  z-index: 200000;
}

.sidebarContainer,
.sidebarShowBtnError,
.sidebarShowBtn,
.sidebarHideBtn {
  display: none;
}

/* Dropdown */
.mwi-dropdown.dobMonth {
  width: 94% !important;
}

.coverage-wrapper .tab-nav .label.short {
  display: none;
}

.coverage-wrapper .tab-nav .label.full {
  display: block;
}

@media (max-width: 1024px) {
  .coverage-wrapper .tab-nav .label.short.too-many {
    display: block;
  }

  .coverage-wrapper .tab-nav .label.full.too-many {
    display: none;
  }

  .coverage-wrapper .tab-nav .tab-header {
    /* display: none; */
    width: 22% !important;
  }

  .coverage-wrapper .tab-nav .tab-header.add {
    /* display: none; */
    width: 5% !important;
  }

  .coverage-wrapper .tab-nav .tab-header.too-many {
    /* display: none; */
    width: 16% !important;
  }
}

@media (max-width: 999px) {
  .summary-wrapper {
    display: none;
  }

  .coverage-wrapper {
    width: 100%;
  }

  .sidebarContainer,
  .sidebarShowBtnError,
  .sidebarShowBtn,
  .sidebarHideBtn {
    display: block;
  }
}

.spreadsheet-link {
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  text-align: right;
  color: #004427;
  text-decoration: underline;
  -webkit-text-decoration-color: #00693c;
  text-decoration-color: #00693c;
  cursor: pointer;
  margin-right: -24px;
  padding-bottom: 10px !important;
}

.spreadsheet-div {
  text-align: right;
  margin-bottom: 10px;
  margin-top: -20px;
}

.ddaStartNewbtn {
  position: relative;
  display: block;
  top: 20px;
  background-color: #ffffff !important;
  color: #4e803b !important;
  font-size: 14px !important;
  border: 1px solid #4e803b !important;
  width: 100%;
  height: 44px !important;
}
