.premium-offset-year-wrapper {
  padding-top: 24px;
}

.pmax-payments-from-year-wrapper {
  padding-top: 24px;
}

.pmax-payments-to-year-wrapper {
  padding-top: 24px;
}

.premium-offset-year-label {
  padding-top: 9px;
  padding-right: 20px;
}

.planned-base-label {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}