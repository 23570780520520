.p-col-2.joint-rating-container {
  position: absolute !important;
  top: 0;
  right: 0;
}
.joint-rating-container .joint-rating-btn {
  position: relative;
  top: 0;
}
.edit-rating {
  margin-top: 22px !important;
  margin-left: 0 !important;
  max-width: 100px !important;
}

.joint-rating {
  margin-top: 0 !important;
}

.ReactModal__Content.payorTemplate {
  width: 396px;
  height: 206px !important;
  position: relative;
  margin: 0 auto !important;
  padding: 0 !important;
  background: rgb(255, 255, 255);
}

@media (max-width: 1024px) {
  .joint-rating-container .joint-rating-btn {
    width: 85% !important;
  }
}
