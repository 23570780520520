.vitality-status-wrapper {
  /* width: 100%; */
  /* margin-top: 10px; */
}
.p-col-12.vitality-status.multiple:not(:last-child) {
  margin-bottom: 5px !important;
}

.vitality-status .material-icons {
  font-size: 18px !important;
}

.help {
  display: inline-table;
  cursor: pointer;
  color: #000;
}

.help:focus {
  outline: none;
}

.vitality-help > .material-icons {
  font-size: 16px !important;
}

.vitality-help {
  position: absolute;
  bottom: 0;
  margin-left: 6px;
  top: 6px;
  right: 6px;
}

.vitality-help .p-overlaypanel {
  width: 300px !important;
}

.help:focus > .material-icons {
  outline: solid 5px -webkit-focus-ring-color !important;
  outline: solid 2px #7aacfe !important;
}

.lgshow {
  display: block !important;
}
.mdshow {
  display: none !important;
}
#vitalityBtnWrapper {
  margin-top: 24px;
}

.vitality-status-helptext-size-en {
  width: 500px !important;
  max-width: 500px !important;
}

.vitality-status-helptext-size-fr {
  width: 600px !important;
  max-width: 600px !important;
}

.display-inline {
  display: inline;
}

.healthy-living-link a {
  color: #004427;
  text-decoration: none;
  text-decoration-color: #00693c;
  cursor: pointer;
  border-bottom: 1px solid;
}

.healthy-living-link a:hover{
    text-decoration: none;
}

.trc-help .p-overlaypanel {
  z-index: 99999 !important;
}

.text-align-right{
  text-align: right;
}

@media (max-width: 1024px) {
  .vitality-status .p-col-6 {
    /* So all input fields remain on the same line */
    width: 42% !important;
  }
  .mdshow {
    display: block !important;
  }
  .lgshow {
    display: none !important;
  }
}
