.ftrc-header {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b6e6e;
}

.text-size14 {
  font-size: 14px;
}

.link-container {
  margin-top: 12px;
}

.printer-link {
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  float: right;
  color: #004427;
  text-decoration: underline;
  -webkit-text-decoration-color: #00693c;
  text-decoration-color: #00693c;
  cursor: pointer;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.right-align {
  margin-left: auto;
}

.link-space {
  margin-left: 24px;
}

@media (max-width: 999px) {
  .summary-wrapper {
    display: block;
    width: 280px !important;
  }

  .summary-header {
    width: 280px;
  }

  .coverage-wrapper {
    width: calc(100% - 280px);
  }

  #no-margin-progress-bar > div:nth-child(2) {
    display: flex;
  }

  .healthstyle {
    padding-left: 0 !important;
    margin-top: 18px !important;
  }

  .healthstyle-trc {
    margin-top: 18px !important;
    width: 41.6667%;
  }
}

/* For Printer-view & small screen */
@media (max-width: 750px) {
  .healthstyle {
    padding-left: 0 !important;
    margin-top: 18px !important;
  }

  .remove-button {
    margin-top: -10px;
    padding: 10px 0 10px 0;
  }

  .cov-option {
    margin-top: 24px;
  }

  .hs-help {
    margin-top: 18px;
  }

  .healthstyle-trc {
    margin-top: 18px !important;
    width: 41.6667%;
  }

  .trc-sub-section {
    width: 100%;
  }

  .trc-coverage-ef {
    width: 50%;
  }

  .trc-product-desc {
    width: 33.3333%;
  }

  .trc-coverage-amount {
    width: 58.3333%;
  }

  .trc-premium-frequency {
    width: 41.6667%;
  }

  .trc-sex {
    width: 50%;
  }

  .trc-dob {
    width: 50%;
  }

  .p-tabview-nav li.en a {
    padding-right: 0.55em !important;
    padding-left: 0.55em !important;
  }

  .p-tabview-nav li.fr a {
    padding-right: 0.15em !important;
    padding-left: 0.15em !important;
  }

  .scenario-add {
    width: 160px;
  }
}
