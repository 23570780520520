.opened-case-container {
  display: flex;
  align-items: baseline;
}

.openedcase-header {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b6e6e;
  padding-left: 1em;
  margin-left: 80px;
}

.openedcase-subheader {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b6e6e;
  padding-left: 1em;
  margin-left: 95px;
  padding-top: 10px;
}

.openedcase-state {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737373;
  padding-left: 1.5em;
  padding-top: 1em;
}

.case-state-icon {
  color: #046138;
  font-size: 1.3em !important;
  vertical-align: bottom;
}

.case-state-error {
  color: red;
}

.case-state-icon-error {
  font-size: 1.3em !important;
  vertical-align: bottom;
}

.openedcase-state-text {
  padding-left: 0.8em;
  padding-bottom: 0.2em;
}

.clientCoverage {
  /*margin-bottom: 1em;*/
}

.selectLink {
  color: #004427;
  font-size: 14px;
  text-decoration: underline;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}
