.learnmore-table > * > table {
  display:block;
  border-spacing: 0px;
  font-size: 13px;
  text-align: left;
}

th.learnmore-cell {
  text-align: left !important;
  background-color: #e9f0ed !important;
  color: #004427 !important;
  border: 1px solid #c8c8c8 !important;
  position: relative !important;
  height: 62px;
}

td.learnmore-cell {
  background-color: white !important;
  border: 1px solid #c8c8c8 !important;
  position: relative !important;
}

.learnmore-cell > div, .learnmore-cell > span {
  padding: 10px !important;
  top: 0px !important;
}

th.learnmore-cell > span {
  position: absolute;
}

.learn-more-absolute {
  position: absolute;
  margin-top: 5px !important;
}

.learnmore-cell > div > span > ul {
  padding-left: 15px;
}

.learnmore-cell > div > span > ul > li {
  padding-bottom: 7px;
}

th.learnmore-cell:first-of-type {
  width: 15%;
}

th.learnmore-cell:nth-of-type(2) {
  width: 30%;
}

.helptext-size {
  width:900px !important;
  max-width: 900px !important;
}

@media (max-width: 1050px) {
  .helptext-size {
    width: 600px !important;
  }
}

.learn-more-details-list{
  padding-left: 2em;
}