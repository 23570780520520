.error-panel {
  height: 60px;
  background-color: #fbcd62;
  color: #111212;
  position: relative;
  margin: 0 0 20px;
  z-index: 100;
  display: flex;
  align-items: center;
}
.error-msg {
  font-size: 14px;
  text-align: center;
  display: flex;
}
#error-msg-wrapper {
  margin: auto;
}
#error-msg-wrapper > a {
  color: inherit;
}
.error-icons {
  display: flex;
}
#cancel-button-wrapper {
  float: right;
  cursor: pointer;
}
