.mwi-button {
  color: #ffffff;
  font-weight: 500;
  background-color: #4e803b !important;
  border-color: #4e801f !important;
  border-width: 2px !important;
}
.mwi-button.with-padding {
  padding: 0 20px;
}
.mwi-button.with-height {
  height: 60%;
  margin-top: 10px;
}
.mwi-button:hover {
  background-color: #00693c !important;
  border-color: #00693c !important;
  color: white !important;
}
.mwi-button.yellow-bg:hover {
  opacity: 0.8;
  color: #000 !important;
  background-color: #ffb500 !important;
  border-color: #ffb500 !important;
}

.mwi-button.yellow-bg:active,
.mwi-button.yellow-bg:focus {
  color: #000 !important;
}

@media (max-width: 1146px) {
  .mwi-button.md {
    width: 180px;
  }
}
@media (max-width: 1024px) {
  .mwi-button.sm {
    width: 150px;
  }
}
button[role='button']:focus {
  border: 1px solid #2399e5 !important;
}
