.spreadsheet {
  /* position: relative; */
  font-size: 13px;
}

.spreadsheet .actions {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -42px;
  height: 40px;
  margin-bottom: -40px;
  /* left: 480px; */
  z-index: 11001;
  float: right;
  background-color: #00693c;
}

.spreadsheet .p-datatable {
  width: 100% !important;
  font-family: Roboto, Trebuchet MS, Arial, Helvetica, sans-serif;
}

.spreadsheet .p-datatable thead tr {
  height: 65px;
}

.spreadsheet .sp-column {
  width: 181px;
  padding: 0 !important;
}

.spreadsheet .sp-column.PolicyYear,
.spreadsheet .sp-column.PrimaryAttainedAge {
  width: 48px;
  text-align: center;
}

.spreadsheet .sp-column.ExpenseDeduction {
  width: 110px;
}

.spreadsheet .sp-column.AdjustedCostBasis {
  width: 135px;
}

/* NOTE: Remove this style once calcs. returns proper header name */
.spreadsheet .sp-column.TotalT10ModalPremium {
  width: 210px;
}

/****/

.spreadsheet table {
  table-layout: fixed;
  text-align: right;
  width: 100%;
}

.spreadsheet table,
.spreadsheet td,
.spreadsheet th {
  border-left: 1px solid #c0c0c0 !important;
  border-right: 1px solid #c0c0c0 !important;
}

.spreadsheet th {
  font-size: 14px;
  background-color: #e2e2e2 !important;
  padding: 10px !important;
  white-space: normal !important;
  font-family: Arial, Helvetica, sans-serif;
}

.p-datatable tbody > tr.ui-widget-content.p-datatable-odd {
  background-color: #ffffff;
}

.spreadsheet .p-progressbar {
  height: 10px !important;
}

.spreadsheet .p-datatable-scrollable-body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.spreadsheet .p-datatable-scrollable-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.spreadsheet .p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

.spreadsheet .p-datatable-scrollable-body::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.spreadsheet .p-datatable-scrollable-body::-webkit-scrollbar:vertical {
  width: 10px;
}

.spreadsheet .p-datatable-scrollable-body::-webkit-scrollbar:horizontal {
  height: 10px;
}

.p-highlight-spreadsheet {
  background-color: #e3f4f1 !important;
}

.p-even {
  background-color: #fff !important;
}

.sp-column.IllustratedDepositOptionPayments.custom {
  padding: 0px 0px !important;
}

.sp-column.RequestedAnnualDeposit.custom {
  padding: 0px 0px !important;
}

.sp-column.custom.changed:not(th) {
  background-color: #c0c0c0 !important;
}

.depositEditorContainer {
  height: 27px;
  background-color: rgba(0, 35, 224, 0.1) !important;
  padding: 3px !important;
}

.depositEditor {
  height: 22px !important;
  border-radius: 3px;
  border: solid 1px;
  border: solid 1px #e0e3e2;
  background-color: #ffffff;
  padding: 5px 5px !important;
}

.spreadTitle {
  float: left;
  padding: 35px 0px !important;
  font-size: 14px;
  text-align: left;
  color: white;
  -ms-flex-item-align: center;
  align-self: center;
  position: absolute !important;
  left: 25px !important;
  font-family: OpenSans-light, serif;
  width: 45% !important;
  /* border-left: solid 1px #ffffff; */
}

.spreadControls {
  justify-content: flex-end;
  display: flex;
  float: right;
  align-items: center;
}

.yellow-bg.exportBtn {
  width: 17% !important;
}

@media screen and (max-width: 1024px) {
  .yellow-bg.exportBtn.fr {
    width: 19% !important;
  }
}
