.bank-loan-rate {
  margin-right: 0.85%;
}
.loan-amount-input {
  padding-left: 25px;
}
#youngestLife {
  -webkit-text-fill-color: #262626;
  color: #262626 !important;
  opacity: 0.7 !important;
}
.collateral-container {
  margin-top: 0px;
}
@media (max-width: 1024px) {
  .collateral-container {
    margin-top: 24px;
  }
}
