.profile-details {
  padding: 0 20px;
  margin-bottom: 40px;
}
.profile-subwrapper {
  padding: 20px;
  border: 1px solid #c0c0c0;
}
.profile-subwrapper > .p-grid:not(:last-child) {
  margin-bottom: 20px;
}
.profile-details .actions {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -60px;
  margin-bottom: -40px;
  /* left: 480px; */
  z-index: 11001;
}
.profile-details .actions button {
  height: 44px;
  max-width: 164px;
  font-size: 14px;
}
.profile-details .actions button:not(:last-child) {
  margin-right: 15px;
}
.profile-details .mwi-button:hover {
  color: #005844 !important;
  border: 1px solid #005844 !important;
  background-color: white !important;
}

@media (max-width: 1024px) {
  .profile-subwrapper .mwi-w100 {
    width: 110% !important;
  }
}
