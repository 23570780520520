.delete-dialog-wrapper .title-model {
  height: 47px;
  padding: 16px !important;
  font-size: 14px;
  font-weight: bold;
  background-color: #f7faf9;
  color: #00693c;
  padding-left: 24px !important;
}

.delete-dialog-wrapper .row-model {
  padding-left: 24px;
  margin-bottom: 0 !important;
  margin-top: 24px !important;
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
}

.delete-dialog-wrapper .mwi-white-button:hover {
  background-color: white !important;
  border-color: #00693c !important;
  color: #4e803b !important;
}

.ReactModal__Content.delete-dialog-wrapper {
  width: 365px !important;
  height: 173px !important;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  font-size: 13px;
}

.dialog-footer {
  padding: 24px !important;
  margin-top: 24px;
}
.dialog-footer div:last-child {
  text-align: right;
}
.dialog-footer div:first-child {
  text-align: left;
}
.dialog-footer button {
  margin-right: 24px;
}
