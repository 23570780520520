.opens-in-new-tab {
  color: #111212;
  font-size: 13px !important;
  text-align: center;
  vertical-align: middle;
  padding-left: 4px;
}

.link-underline:hover, .link-underline:focus {
  text-decoration: underline;
}
