.exportBtn {
  float: right;
  margin-top: 0;
}

.p-grid.tab-nav {
  display: block !important;
}

.tab-section {
  background-color: #e9f0ed !important;
}

.scenario-add {
  height: 46px;
  top: 12px;
  padding: 12px 0 4px 8px !important;
}

.add-scenario-link {
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #00693c;
  text-decoration-color: #00693c;
  cursor: pointer;
  color: #004427;
  padding-left: 24px;
}

.tabh-content {
  margin-top: 12px !important;
  margin-right: 1em !important;
  padding-bottom: 12px !important;
  margin-left: 1em !important;
  width: 8em !important;
}

.tabh.active {
  background-color: inherit;
  font-weight: 700;
}

.tabh.active > .tabh-content {
  border-bottom: 4px solid #004427;
}

.tabh .tab-title {
  color: #004427;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
}

.tabh.active .tab-title {
  font-weight: 700;
}

.tabh {
  margin-right: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  border-radius: 0;
  background-color: inherit;
  text-align: center;
}

.tabh .tab-title:hover {
  cursor: pointer;
}

.tab-icon {
  font-size: 16px !important;
  max-width: 23px;
  color: #004427;
}

.tab-icon:hover {
  cursor: pointer;
}

.scenarioTab-wrapper .label {
  vertical-align: middle;
}

.scenarioTab-wrapper {
  margin-top: -1px;
}

.scenarioTab-wrapper-nopadding {
  padding-top: 3.25px;
  padding-right: 0;
  padding-bottom: 3.25px;
  padding-left: 0;
}

.scenarioTab-fullscreen {
  top: -50px;
  background-color: #e9f0ed !important;
}

@media (max-width: 1024px) {
  .tabh {
    width: 150px;
  }
  .scenario-add {
    width: 120px;
  }
}
