.click {
  color: #008048;
  text-decoration: underline;
  display: block;
  text-align: left;
  font-size: 16px;
}

.click:hover {
  text-decoration: none;
}
