/* BANNER */
.banner {
  position: relative;
  height: 200px;
  width: 97.4%;
  margin-left: 1.3%;
  background-color: #222933;
}

.banner .bg-img {
  width: 100%;
  height: 100%;
  /* opacity: 0.6; */
  /* background-image: url('/images/welcome-banner.jpg'); */
}

.banner .caption h1 {
  position: absolute;
  top: 70px;
  left: 25px;
  width: 400px;
  color: #00693c;
  font-size: 36px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  /* .banner {
    top: -20px;
  } */
}
.skipnav a {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
a.skip-main:focus,
a.skip-main:active {
  -webkit-appearance: none;
  -webkit-text-fill-color: #00693c;
  -webkit-tap-highlight-color: #fff;
  color: #00693c;
  background-color: #fff;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  overflow: auto;
  text-align: left;
  font-size: 1em;
  z-index: 999;
  margin-left: -40px;
}
