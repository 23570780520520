.ReactModal__Content {
  width: 396px;
  /* max-height: 275px ; */

  position: relative;
  margin: 0 auto !important;
  padding: 0 !important;
  background: rgb(255, 255, 255);
}
.ratings-modal-content {
  height: 275px !important;
}
.ratings-container .label,
.ratings-container .middle {
  top: 7px;
}
.ratings-container .label:not(.unit) {
  text-align: right;
  padding-right: 5px;
}

.ratings {
  position: relative;
  top: 0px;
}

.ratings-container .label span:last-child {
  position: relative;
  top: 1px;
}
.ratings-container .title {
  height: 47px;
  padding: 12px 24px !important;
  /* font-size: 1.25em !important; */
  font-size: 16px;
  font-weight: bold;
  background-color: #f7faf9;
  color: #00693c;
  border-bottom: solid 1px #979797;
}
.ratings-container .middle {
  text-align: center;
}
.ratings-container .unit {
  padding-left: 8px;
  font-size: 14px;
}
.rating-footer {
  padding: 24px !important;
  margin-top: 24px;
}
.rating-footer div:last-child {
  text-align: right;
}
.rating-footer div:first-child {
  text-align: left;
}
.rating-footer button {
  margin-right: 24px;
}
.p-col-12.rating-row {
  padding-left: 24px;
  min-height: 45px;
  margin-bottom: 0 !important;
  margin-top: 12px !important;
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
}
.ratings-container .betweendiv {
  margin-bottom: 0 !important;
  margin-right: 20px !important;
  top: -7px;
}
.dollarSign {
  margin-left: 8px;
}

.reset-cancel-button {
  position: relative;
  display: block;
  top: 1px;
  background-color: #ffffff !important;
  color: #4e803b !important;
  font-size: 14px !important;
  border: 1px solid #4e803b !important;
}

.reset-btn {
  float: left;
}

/* .cancel-btn {
  float: right;
} */

.save-button {
  font-size: 14px !important;
  margin-right: 0px !important;
}

.centerflexdisplay {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
