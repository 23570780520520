.termOther-riders-wrapper {
  position: relative;
  width: 100%;
}
.termOther-riders-wrapper .header-icon {
  position: absolute;
  padding: 21px 0px 21px 0px;
  width: 9px;
  height: 6px;
  left: 24px;
}
.termOther-rider-wrapper {
  padding-bottom: 14px !important;
  margin-bottom: 24px;
}
.termOther-rider-wrapper:first-child {
  margin-top: 24px !important;
}
.termOther-rider-wrapper .splitter {
  margin-bottom: 0px !important;
}
.termOther-rider {
  font-weight: bold;
  color: #4a4a4a;
}
.termOther-rider-checkbox-wrapper .material-icons {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: white;
  pointer-events: none;
}
.termOther-rider .label-group {
  position: relative;
  font-size: 18px !important;
  bottom: 8px;
}
.add-termOther-rider-icon {
  font-size: 18px !important;
  padding-right: 7px !important;
}
.termOther-rider .label-group .label {
  position: absolute;
  font-size: 14px !important;
  font-weight: normal;
  margin-top: 18px;
}
.termOther-rider .label-group .material-icons {
  position: relative;
  top: 10px;
  margin-right: 5px;
  color: #4a4a4a;
  font-size: 37px !important;
}
.termOther-rider .client .p-col-12 .mwi-label {
  display: block;
}
.mwi-icon.add-rider {
  padding-right: 5px;
}
.termOther-rider .mwi-icon.add-rider {
  /* top: 5px; */
  padding-right: 5px;
}
.termOther-rider .udm-id {
  top: 0;
  left: 60px;
}
.termOther-rider .delete-wrapper {
  display: inline-block;
  padding-left: 5px;
  background-color: white;
  color: #4a4a4a !important;
  cursor: pointer;
}
.termOther-rider .delete-wrapper span {
  position: absolute;
  top: 5px;
}
.termOther-rider .delete-wrapper.fr {
  width: 135px !important;
}
.termOther-rider .delete-wrapper .delete-rider {
  margin-right: 5px;
  font-size: 18px !important;
  top: 2px;
}

.termOther-rider .rider-info {
  font-weight: normal;
}

.termOther-rider .rider-options {
  margin-top: 24px;
}

.duration-label-padding {
  padding-top: 9px;
  /* float: right; */
}

.p-col-12 .client:not(.insured) {
  margin-top: 24px;
  margin-bottom: 24px;
}
.header-labels {
  font-weight: bold;
}
.termOther-riders-header {
  /* padding-left: 30px; */
  font-size: 14px;
  background-color: #f7faf9 !important;
  border-radius: 0 !important;
  margin-top: 0;
  height: 48px;
  padding: 13px 0px 13px 0px;
}
.termOther-riders-header .ui-accordion-header-text {
  font-size: 16px;
  position: absolute;
  padding-left: 40px;
  font-weight: 600;
  color: #00693c;
}
.remove-button {
  margin-top: -10px;
  padding: 10px 24px 10px 5px;
  cursor: pointer;
}
.hidden-rating {
  visibility: hidden;
}
.ui-accordion-content {
  padding-top: 0 !important;
}
.ui-accordion .ui-accordion-content {
  padding-top: 0 !important;
}
.add-wrapper {
  display: flex;
}

.rating,
.amount {
  position: relative;
  top: -2px !important;
  height: 25px;
}
.termOther-rider .select-client {
  padding: 2px 0 !important;
}

.termOther-rider-checkbox-wrapper {
  position: relative;
  top: 3px;
  outline: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.termOther-rider-checkbox-wrapper .udm-id {
  top: 0;
  right: 0;
}
#cov-client-wrapper .coverage-client .client-selection .mwi-label {
  display: none;
}
#cov-client-wrapper .client .label-group .material-icons {
  top: 6px;
  margin-right: 8px;
}
.termOther-rider-checkbox {
  margin-right: 5px;
  vertical-align: middle;
}
.ui-accordion .mwi-expanded {
  padding-left: 12px !important;
}

.ui-accordion .ui-accordion-content .rider {
  margin: 10px 0 2px 0 !important;
  padding: 4px 0 4px 0;
  min-height: 38px;
}
.ui-accordion .ui-accordion-content .rider:not(.ChildProtectionRider) {
  border-bottom: 1px dashed lightgray;
}
.ui-accordion .ui-accordion-content .rider:last-child {
  border-bottom: none;
}
.ui-accordion .ui-accordion-header {
  border-bottom: 0 !important;
  margin-top: 0px !important;
}
.ui-accordion .ui-accordion-content-wrapper-expanded,
.ui-accordion .ui-accordion-content-wrapper-collapsed {
  border: 1px solid lightgray !important;
  border-top: 0 !important;
}
.mwi-expanded.ui-accordion-content-wrapper.ui-accordion-content-wrapper-expanded,
.ui-accordion .ui-accordion-content-wrapper-collapsed,
.ui-accordion .ui-accordion-header {
  border: none !important;
}
.ui-accordion-content-wrapper-collapsed > div {
  /* For AODA so it doesn't read all the riders when collapsed... */
  display: none;
}

@media (max-width: 1024px) {
  .select-client input {
    padding: 2px 2px 2px 4px !important;
  }
}
