.additional-years-txt {
  margin-top: 30px;
  /* display: flex;
  align-content: center; */
}
.life-expectancy-assumption {
  margin-bottom: 20px;
}
.illustrate-additional-container {
  display: flex;
  flex-direction: row;
}
