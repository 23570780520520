.app {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 950px;
}

.mwi-main {
  position: relative;
  padding: 0;
  min-height: calc(100vh - 128px - 112px - 101px);
  z-index: 1;
}

.paddingLeft80 {
  padding-left: 80px;
}

.mwi-content-margin {
  margin: 0 30px 0 20px !important;
}

.error-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -100;
  background: rgba(155, 155, 155, 0.5);
}
.error-wrapper.error {
  z-index: 10001 !important;
}
.error-wrapper .hidden-message {
  margin-top: 10px;
  display: none;
  font-weight: bold;
}
.request-error {
  top: 250px;
  min-height: 200px !important;
  left: 0 !important;
  margin: auto;
  position: relative;
  z-index: 20000;
}
.ui-dialog-footer {
  border: none !important;
}
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.content-wrapper {
  top: 140px;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-bottom: 40px;
  padding: 0 20px;
}
.content-title-bar {
  padding: 10px 25px;
  background-color: #00693c;
  height: 90px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.content-title-bar > span:first-child {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .app {
    max-width: 1024px;
    margin: 0 0;
  }
}
@media (max-width: 1367px) {
  .app {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 670px;
  }
}

.leftSideBarLayout {
  max-width: 80px;
}
