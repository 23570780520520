.p-dialog-wrapper-visible {
  z-index: 10002 !important;
  justify-content: flex-end;
  align-items: stretch;
  left: calc(100% - 508px);
  top: 210px;
  height: calc(100% - 214px);
  width: 504px;
}

.udm-viewer {
  overflow: auto;
  /* opacity: 0.8 !important; */
}
