@font-face {
  font-family: 'Manupictos';
  src: url('../../assets/fonts/manupictos/manupictos-regular.eot');
  src: url('../../assets/fonts/manupictos/manupictos-regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/manupictos/manupictos-regular.woff2') format('woff2'),
    url('../../assets/fonts/manupictos/manupictos-regular.woff') format('woff'),
    url('../../assets/fonts/manupictos/manupictos-regular.ttf') format('truetype');
}

.manupicto {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Manupictos', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.manupicto-arrow-up:before {
  content: '\e100';
}
.manupicto-arrow-left:before {
  content: '\e101';
}
.manupicto-arrow-down:before {
  content: '\e102';
}
.manupicto-arrow-right:before {
  content: '\e103';
}
.manupicto-circled-arrow-up:before {
  content: '\e104';
}
.manupicto-circled-arrow-left:before {
  content: '\e105';
}
.manupicto-circled-arrow-down:before {
  content: '\e106';
}
.manupicto-circled-arrow-right:before {
  content: '\e107';
}
.manupicto-plus:before {
  content: '\e108';
}
.manupicto-circled-plus:before {
  content: '\e109';
}
.manupicto-minus:before {
  content: '\e10A';
}
.manupicto-circled-minus:before {
  content: '\e10B';
}
.manupicto-saltire:before {
  content: '\e10C';
}
.manupicto-circled-saltire:before {
  content: '\e10D';
}
.manupicto-menu:before {
  content: '\e10E';
}
.manupicto-sign-in:before {
  content: '\e10F';
}
.manupicto-search:before {
  content: '\e110';
}
.manupicto-notification:before {
  content: '\e111';
}
.manupicto-contact {
  display: none;
}
.manupicto-for-you:before {
  content: '\e200';
}
.manupicto-for-your-business {
  display: none;
}
.manupicto-advisors {
  display: none;
}
.manupicto-for-your-home {
  display: inline-block;
}
.manupicto-for-your-home:before {
  position: relative;
  top: 3px;
  content: '\e203';
}
.manupicto-retirement {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-retirement:before {
  content: '\e400';
}
.manupicto-extra-income {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-extra-income:before {
  content: '\e401';
}
.manupicto-family {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-family:before {
  content: '\e402';
}
.manupicto-buy-a-home {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-buy-a-home:before {
  content: '\e403';
}
.manupicto-starting-out {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-starting-out:before {
  content: '\e404';
}
.manupicto-health {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-health:before {
  content: '\e405';
}
.manupicto-investment {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-investment:before {
  content: '\e406';
}
.manupicto-banking {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-banking:before {
  content: '\e407';
}
.manupicto-international {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-international:before {
  content: '\e408';
}
.manupicto-workforce {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-workforce:before {
  content: '\e409';
}
.manupicto-group {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-group:before {
  content: '\e40A';
}
.manupicto-circled-retirement {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-retirement:before {
  content: '\e400';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-extra-income {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-extra-income:before {
  content: '\e401';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-family {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-family:before {
  content: '\e402';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-buy-a-home {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-buy-a-home:before {
  content: '\e403';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-starting-out {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-starting-out:before {
  content: '\e404';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-health {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-health:before {
  content: '\e405';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-investment {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-investment:before {
  content: '\e406';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-banking {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-banking:before {
  content: '\e407';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-international {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-international:before {
  content: '\e408';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-workforce {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-workforce:before {
  content: '\e409';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-group {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-group:before {
  content: '\e40A';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-twitter {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-twitter:before {
  content: '\e600';
}
.manupicto-facebook {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-facebook:before {
  content: '\e601';
}
.manupicto-email {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
}
.manupicto-email:before {
  content: '\e602';
}
.manupicto-circled-twitter {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-twitter:before {
  content: '\e600';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-facebook {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-facebook:before {
  content: '\e601';
  position: relative;
  top: 0;
  left: -2px;
}
.manupicto-circled-email {
  font-size: 6.4rem;
  height: 6.4rem;
  width: 6.4rem;
  line-height: 1.2;
  border: 2px solid #464646;
  border-radius: 50%;
}
.manupicto-circled-email:before {
  content: '\e602';
  position: relative;
  top: 0;
  left: -2px;
}
