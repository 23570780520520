.ReactModal__Content.status-dialog-wrapper {
  width: 405px !important;
  height: auto !important;
  position: relative;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.status-dialog-wrapper .title-model {
  height: 47px;
  padding: 16px !important;
  font-size: 14px;
  font-weight: bold;
  background-color: #f7faf9;
  color: #00693c;
  text-align: center;
}

.status-dialog-wrapper .row-model {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 0 !important;
  margin-top: 24px !important;
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
}

.status-dialog-wrapper .dialog-footer {
  justify-content: center;
}

.status-dialog-wrapper .mwi-white-button:hover {
  background-color: white !important;
  border-color: #00693c !important;
  color: #4e803b !important;
}
